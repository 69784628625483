<template>
  <div class="content">
    <div class="md-layout">
    
      <div class="md-layout-item md-medium-size-100 md-size-33">
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Event Detail</h4>
          </md-card-header>

          <md-card-content>
            <form @submit.prevent="editEvent">
              <div class="md-layout">
                <div 
                  class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Name</label>
                    <md-input v-model="tempData.name" :disabled="!isEdit"></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Place</label>
                    <md-input v-model="tempData.place" :disabled="!isEdit"></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field v-if="!isEdit">
                    <label>Date Start</label>
                    <md-input v-model="tempData.dateStartFormatted" disabled></md-input>
                  </md-field>
                  <div v-else>
                    <label>Date Start</label>
                    <md-datepicker v-model="tempData.dateStartFormatted" />
                  </div>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field v-if="!isEdit">
                    <label>Date End</label>
                    <md-input v-model="tempData.dateEndFormatted" :disabled="isEdit"></md-input>
                  </md-field>
                  <div v-else>
                    <label>Date End</label>
                    <md-datepicker v-model="tempData.dateEndFormatted"/>
                  </div>
                </div>

                <div v-if="!isEdit" class="md-layout-item md-small-size-100 md-size-100">
                  <md-field>
                    <label>Created At</label>
                    <md-input v-model="tempData.createdAtFormatted" disabled></md-input>
                  </md-field>
                </div>

                <div v-if="!isEdit" class="md-layout-item md-small-size-100 md-size-100">
                  <md-field>
                    <label>Last Updated</label>
                    <md-input v-model="tempData.updatedAtFormatted" disabled></md-input>
                  </md-field>
                </div>
              </div>
              <md-button
                v-if="!isEdit"
                @click="isEdit = true"
                class="me-2 md-warning"
              >
                Edit
              </md-button>

              <md-button
                v-if="isEdit"
                type="submit"
                class="me-2 md-success"
              >
                Save
              </md-button>

              <md-button
                v-if="isEdit"
                class="md-danger"
                size="md"
                @click="resetEventData()"
              >
                Cancel
              </md-button>
            </form>
          </md-card-content>
        </md-card>
      </div>
      <div class="md-layout-item md-medium-size-100 md-size-66">
        <list-collected-number :data="eventDetail.collectedPhoneNumbers" :eventData="eventDetail.event[0]" @refreshData="requestDataAPI"></list-collected-number>
      </div>
    </div>

    <md-snackbar :md-position="'center'" :md-duration="5000" :md-active.sync="snackbar.show" md-persistent>
      <span>{{ snackbar.message }}</span>
      <md-button class="md-primary" @click="snackbar.show = false">Close</md-button>
    </md-snackbar>
  </div>
</template>

<script>

export default {
  components: {
  },
  data(){
    return{
      snackbar:{
        message: "",
        show: false,
      },

      eventData:{},
      tempData:{},
      isEdit: false,
      phoneNumberCollapseShow: true,
      handledBy: "Marketing 1",
      eventDetail: {},
    }
  },
  computed:{
    currentEventId(){
      return this.$route.params.id;
    },
    events(){
      return this.$store.state.events;
    },
    collectedPhoneNumbers(){
      return this.$store.state.collectedPhoneNumbers;
    }
  },
  watch:{
    eventData(){
      const vm = this;

      vm.eventData.createdAtFormatted = new Date(vm.eventData.created_at).toLocaleDateString();
      vm.eventData.updatedAtFormatted = new Date(vm.eventData.updated_at).toLocaleDateString();
      vm.eventData.dateStartFormatted = new Date(vm.eventData.date_start).toLocaleDateString('en-CA');
      vm.eventData.dateEndFormatted = new Date(vm.eventData.date_end).toLocaleDateString('en-CA');

      vm.tempData = JSON.parse(JSON.stringify(vm.eventData));
    },
    eventDetail(){
      const vm = this;

      vm.eventData = vm.eventDetail.event[0];
    }
  },
  methods:{
    validateUser () {
      alert("tes")
    },

    async requestDataAPI(url){
      const vm = this;

      vm.dataIsLoading = true;

      await vm.axios.get(`${vm.$store.state.baseApiURL}/api/events/details/${vm.currentEventId}`, 
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.eventDetail = response.data;
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.requestDataAPI(url);
          }
        }
        else{
          vm.showSnackbar(`Failed to Get Event Data with ID: ${vm.currentEventId}`, err.message);
        }
      })
      
      vm.dataIsLoading = false;
    },

    async editEvent() {
      const vm = this;
      
      vm.$store.state.isLoading = true;

      await vm.axios.put(`${vm.$store.state.baseApiURL}/api/events/update/${vm.currentEventId}`, {
        name: vm.tempData.name,
        place: vm.tempData.place,
        date_start: new Date(vm.tempData.dateStartFormatted),
        date_end: new Date(vm.tempData.dateEndFormatted)
      }, 
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.isEdit = false;
        vm.requestDataAPI();
        vm.showSnackbar("Update Event Success!", "");
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.editEvent();
          }
        }
        else{
          vm.showSnackbar("Failed to Update the Event", err.message);
        }
      })

      vm.$store.state.isLoading = false;
    },

    resetEventData(){
      const vm = this;
      vm.tempData = JSON.parse(JSON.stringify(vm.eventData));
      vm.isEdit = false;
    },
    
    showSnackbar(title, message) {
      this.snackbar.message = `${title}`;
      this.snackbar.show = true;
    },
  },
  async mounted(){
    const vm = this;

    await vm.requestDataAPI();

    vm.tempData = JSON.parse(JSON.stringify(vm.eventData));
  }
};
</script>

<style scoped>
  .event-detail{
    border-bottom: "solid 1px gray";
  }
</style>
