<script>
// CommitChart.js
import { Line } from 'vue-chartjs'
 
export default {
  extends: Line,
  props:{
    backgroundColor: {
      type: String,
      default: 'rgb(0,255,0)'
    },
    label: {
      type: String,
      default: 'label'
    },
    data: {
      type: Array,
      default: () => []
    },
    labels: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
    const vm = this;
    // Overwriting base render method with actual data.
    this.renderChart({
      labels: vm.labels,
      datasets: [
        {
          label: vm.label,
          backgroundColor: vm.backgroundColor,
          data: vm.data
        }
      ]
      /*labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      datasets: [
        {
          label: 'GitHub Commits',
          backgroundColor: '#f87979',
          data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
        }
      ]*/
    }, {responsive: true, maintainAspectRatio: false})
  }
}
</script>