import Vue from "vue";
import VueRouter from "vue-router";

import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";

/*import Dashboard from "@/pages/Dashboard.vue";
import UserProfile from "@/pages/UserProfile.vue";
import TableList from "@/pages/TableList.vue";
import Typography from "@/pages/Typography.vue";
import Icons from "@/pages/Icons.vue";
import Maps from "@/pages/Maps.vue";
import Notifications from "@/pages/Notifications.vue";
import UpgradeToPRO from "@/pages/UpgradeToPRO.vue";*/

import Login from "@/pages/Login.vue";
import Customer from "@/pages/Customer.vue";
import Event from "@/pages/Event.vue";
import PriceList from "@/pages/PriceList.vue";
import Statistics from "@/pages/Statistics.vue";
import Step from "@/pages/Step.vue";
import ManageUser from "@/pages/ManageUser.vue";
import AcceptTransaction from "@/pages/AcceptTransaction.vue";
import CurrentUserProfile from "@/pages/CurrentUserProfile.vue";
import HelpAndGuide from "@/pages/HelpAndGuide.vue";

import CustomerDetail from "@/pages/CustomerDetail.vue";
import EventDetail from "@/pages/EventDetail.vue";
import UserDetail from "@/pages/UserDetail.vue";

import store from "../stores/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/accept-transaction/:idTransaction/:idMarketing",
    name: "Accept Transaction",
    component: AcceptTransaction,
  },
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "customer",
        name: "Customer",
        component: Customer,
      },
      {
        path: "customer/:id",
        name: "Customer Detail",
        component: CustomerDetail,
      },

      {
        path: "event",
        name: "Event",
        component: Event,
      },
      {
        path: "event/:id",
        name: "Event Detail",
        component: EventDetail,
      },

      {
        path: "step",
        name: "Step",
        component: Step,
      },

      {
        path: "price-list",
        name: "Price List",
        component: PriceList,
      },

      {
        path: "manage-user",
        name: "Manage User",
        component: ManageUser,
      },
      {
        path: "manage-user/:id",
        name: "User Detail",
        component: UserDetail,
      },

      {
        path: "profile",
        name: "Current User Profile",
        component: CurrentUserProfile,
      },

      {
        path: "statistic",
        name: "Statistic",
        component: Statistics,
      },

      {
        path: "help",
        name: "HelpAndGuide",
        component: HelpAndGuide
      }

      /*{
        path: "user",
        name: "User Profile",
        component: UserProfile,
      },
      {
        path: "table",
        name: "Table List",
        component: TableList,
      },
      {
        path: "typography",
        name: "Typography",
        component: Typography,
      },
      {
        path: "icons",
        name: "Icons",
        component: Icons,
      },
      {
        path: "notifications",
        name: "Notifications",
        component: Notifications,
      },*/
    ],
  },
];

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "nav-item active",
});

router.beforeEach((to, from, next) => {
  store.state.user = JSON.parse(window.localStorage.getItem("storagePerumahanUser"));
  store.state.accessToken = JSON.parse(window.localStorage.getItem("storagePerumahanAccessToken"));
  store.state.refreshToken = JSON.parse(window.localStorage.getItem("storagePerumahanRefreshToken"));

  if(store.state.user){
    if(store.state.user.id){
      store.state.isLoggedIn = true;
    }
    else{
      store.state.isLoggedIn = false;
    }
  }
  else{
    store.state.isLoggedIn = false;
  }
  
  let isAuthenticated = store.state.isLoggedIn;

  next()

  if(to.path !== "/login"){
    store.state.nextRoute = to.path;
  }

  if (to.path !== "/login" && !isAuthenticated && to.name !== "Accept Transaction"){
    next({ name:"Login" })
  }
  else{ 
    if(store.state.user){
      let role = store.state.user.role;
      
      if(to.path == "/"){
        if(role == "admin" || role == "marketing"){
          next({ path: "/customer" })
        }
        else if(role == "lead_marketing" || role == "direksi"){
          next({ path: "/statistic" })
        }
        else{
          next({ path: "/login" })
        }
      }
    }
    else{
      next({ path: "/login" })
    }
  }
})

export default router;
