<template>
  <div 
    style="height:100%" 
    class="login-background" 
    :style="{ backgroundImage: 'url(' + require('@/assets/img/login-page-background.jpg') + ')' }"
    >
    <div class="layer">
      
    </div>
    <b-container class="h-100 py-5 text-center">
      <b-row class="w-100 my-5">
        <b-col align-self="center">
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters>
              <b-col md="6">
                <b-card-img 
                  alt="Image" 
                  :src="containerImage"
                  class="rounded-0"></b-card-img>
              </b-col>
              <b-col md="6">

                <b-card-body v-if="transactionIsAvailable">
                  <h3>TRANSACTION NUMBER {{ transactionId }}</h3>
                  <b-card-text>
                    This transaction belongs to user with ID: {{ userId }}. Please press the button below if you're willing to accept the transaction.
                  </b-card-text>

                  <b-button
                    class="my-2"
                    variant="success"
                    size="lg"
                    @click="acceptTransaction()"
                    style="width: 250px"
                  >
                    Accept Transaction
                  </b-button>
                  <br>
                  <b-button
                    class="my-2"
                    variant="danger"
                    size="lg"
                    @click="backToHome()"
                    style="width: 250px"
                  >
                    Back to Home
                  </b-button>

                </b-card-body>

                <b-card-body v-else>
                  <h3>TRANSACTION NUMBER {{ transactionId }}</h3>
                  <b-card-text>
                    This transaction is already <b>ACCEPTED</b>, <b>FINISHED</b>, <b>CANCELED</b>, or doesn't <b>EXIST</b>.
                  </b-card-text>

                  <b-button
                    class="my-2"
                    variant="danger"
                    size="lg"
                    @click="backToHome()"
                    style="width: 250px"
                  >
                    Back to Home
                  </b-button>

                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      ref="modal"
      v-model="prohibitedModalShow"
      :hide-header-close="true"
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="text-center">
        <b-row class="w-100 mx-auto">
          <b-col class="p-0">
            <b-alert show variant="danger">
              <b style="font-size: 1.2rem">Invalid Account or Not Logged In</b>
            </b-alert>
          </b-col>
        </b-row>
        <h5><b>This transaction is not belongs to this account or you're not logged in. We will redirect you to Login Page so you can login with the correct account.</b></h5>
        <hr>
        <b-button
          variant="success"
          size="md"
          class="me-2 px-5"
          @click="redirectToLogin()"
        >
          Ok
        </b-button>
      </div>
    </b-modal>

    <md-snackbar :md-position="'center'" :md-duration="5000" :md-active.sync="snackbar.show" md-persistent>
      <span>{{ snackbar.message }}</span>
      <md-button class="md-primary" @click="snackbar.show = false">Close</md-button>
    </md-snackbar>

    <!-- Loading Modal -->
    <b-modal
      ref="modal"
      v-model="showLoadingModal"
      :hide-header-close="true"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <template #modal-header>
        <b-row class="w-100 mx-auto">
          <b-col class="p-0">
            <b-alert class="text-center" show variant="primary">
              <b style="font-size: 1.2rem">Loading, Please Wait</b>
            </b-alert>
          </b-col>
        </b-row>
      </template>

      <div class="text-center">
        <b-spinner class="mb-2" style="width:5rem; height:5rem; font-size:25px" variant="primary" label="Spinning"></b-spinner>
        <h4>This may take seconds...</h4>
      </div>
    </b-modal>
  </div>
</template>

<script>

export default {
  data() {
    return {
      snackbar:{
        message: "",
        show: false,
      },
      form: {
        username: '',
        password: ''
      },
      show: true,
      invalidAccountMessage: null,
      prohibitedModalShow: false,
      showLoadingModal: false,
      transactionIsAvailable: false,
      transactionData: null,
    }
  },
  components: {
  },
  props:{
    backgroundImage: {
      type: String,
      default: require("@/assets/img/login-page-background.jpg"),
    },
    containerImage: {
      type: String,
      default: require("@/assets/img/login-container-background.jpg"),
    },
  },
  computed:{
    userId(){
      const vm = this;
      return this.$route.params.idMarketing;
    },
    transactionId(){
      const vm = this;
      return this.$route.params.idTransaction;
    },
    currentUser(){
      return this.$store.state.user;
    },
    isLoggedIn(){
      return this.$store.state.isLoggedIn;
    }
  },
  watch:{
    form: {
      handler(val){
        const vm = this;
        vm.invalidAccountMessage = null;
      },
      deep: true
    },
    '$store.state.isLoading': function() {
      const vm = this;
      vm.showLoadingModal = vm.$store.state.isLoading;
    },
  },
  methods:{
    async checkTransaction(){
      const vm = this;
      let url = `${vm.$store.state.baseApiURL}/api/transactions/get/${vm.transactionId}`;

      this.$store.state.isLoading = true;

      await vm.axios.get(url, 
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.transactionData = response.data;
        if(response.data && response.data.progress.step_id == 1){
          vm.transactionIsAvailable = true;
        }
        else{
          vm.transactionIsAvailable = false;
        }
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.requestDataAPI();
          }
        }
        else{
          vm.showSnackbar(`Failed to Get Transaction Data with ID: ${vm.currentUserDetailId}`, err.response.data);
        }
      })
      
      this.$store.state.isLoading = false;
    },

    redirectToLogin(){
      const vm = this;

      vm.$router.push({ path:'/login' });
    },

    async acceptTransaction(){
      // /api/accept-transaction/:idTransaction/:idMarketing

      const vm = this;

      this.$store.state.isLoading = true;
      
      //if (new Date().getHours() < 21 && new Date().getHours() >= 8)
      if(true) {
        await vm.axios.post(`${vm.$store.state.baseApiURL}/api/accept-transaction/${vm.transactionId}/${vm.userId}`,
        null,
        {
          headers: {
            'Authorization': `Bearer ${vm.$store.state.accessToken}`
          }
        })
        .then((response) => {
          vm.$router.push({ path:`/customer/${response.data}` })
        })
        .catch(async err => {
          if(err.response.data.message == "jwt expired"){
            let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
            if(accessToken.toString() == "expired"){
              vm.$router.push({ path:'/login' });
            }
            else{
              vm.acceptTransaction();
            }
          }
          else{
            vm.showSnackbar("Failed to Accept Transaction.", err.response.data.message);
          }
        })
      }
      else{
        vm.showSnackbar("Failed to Accept Transaction.", "The operational hours is between 08:00 till 20:59 (8 AM - 9 PM)");
      }
      
      this.$store.state.isLoading = false;
    },

    showSnackbar(title, message) {
      this.snackbar.message = `${title} ${message}`;
      this.snackbar.show = true;
    },

    backToHome(){
      this.$router.push({ path: '/' })
    }
  },
  mounted(){
    const vm = this;
    
    if(vm.userId != vm.currentUser.id){
      vm.prohibitedModalShow = true;
    }
    else if(!vm.isLoggedIn){
      vm.prohibitedModalShow = true;
    }
    else{
      vm.checkTransaction();
    }
  }
};
</script>

<style>
  .login-background{
    
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .layer {
    background-color: rgba(0,0,0,0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
