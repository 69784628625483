<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header :data-background-color="'purple'">
            <h4 class="title">Help and Guide</h4>
          </md-card-header>

          <md-card-content>
             <div class="accordion" role="tablist">
              <md-card class="mb-1" style="border: 1px solid rgba(80,80,80,0.5);">
                <md-card-header role="tab" data-background-color='green' v-b-toggle.accordion-1>
                  Why do the system need your telegram ID?
                </md-card-header>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                  <md-card-content>
                    The system need your telegram ID for notification sending. There will be some notifications that automatically sent to you through
                    the bot named @perumahan_bot.  
                  </md-card-content>
                </b-collapse>
              </md-card>

              <md-card no-body class="mb-1" style="border: 1px solid rgba(80,80,80,0.5);">
                <md-card-header role="tab" data-background-color='green' v-b-toggle.accordion-2>
                  How to get/know your telegram account ID?
                </md-card-header>
                <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                  <md-card-content>
                    <ol>
                      <li>
                        Search @userinfobot in your telegram app.
                      </li>
                      <li>
                        Open the chat room.
                      </li>
                      <li>
                        Type /start in the chat room.
                      </li>
                      <li>
                        Your telegram ID will be shown in the response.
                      </li>
                    </ol>
                  </md-card-content>
                </b-collapse>
              </md-card>

              <md-card no-body class="mb-1" style="border: 1px solid rgba(80,80,80,0.5);">
                <md-card-header role="tab" data-background-color='green' v-b-toggle.accordion-3>
                  How to start @perumahan_bot for telegram notification?
                </md-card-header>
                <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
                  <md-card-content>
                    <ol>
                      <li>
                        Make sure your account has an active telegram ID (see the guide above).
                      </li>
                      <li>
                        Search @perumahan_bot in your telegram app.
                      </li>
                      <li>
                        Type /start in the chat room.
                      </li>
                      <li>
                        Done, enjoy!
                      </li>
                    </ol>
                  </md-card-content>
                </b-collapse>
              </md-card>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HelpAndGuide'
};
</script>
