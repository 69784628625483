<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>

    <side-bar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
    >
      <!--<mobile-menu slot="content"></mobile-menu>-->
      <!--<sidebar-link to="/dashboard">
        <md-icon>dashboard</md-icon>
        <p>Dashboard</p>
      </sidebar-link>-->
      <sidebar-link 
        to="/profile">
        <md-icon>person</md-icon>
        <p>Profile</p>
      </sidebar-link>
      <sidebar-link 
        v-if="currentUser.role == 'lead_marketing' || currentUser.role == 'direksi'"
        to="/statistic">
        <md-icon>query_stats</md-icon>
        <p>Statistic</p>
      </sidebar-link>
      <sidebar-link to="/customer">
        <md-icon>people</md-icon>
        <p>Customer</p>
      </sidebar-link>
      <sidebar-link 
      v-if="currentUser.role == 'lead_marketing' || currentUser.role == 'direksi'" 
      to="/event">
        <md-icon>location_on</md-icon>
        <p>Event</p>
      </sidebar-link>
      <sidebar-link 
        v-if="currentUser.role == 'admin' || currentUser.role == 'direksi' || currentUser.role == 'marketing'"
        to="/price-list">
        <md-icon>shopping_cart</md-icon>
        <p>Price List</p>
      </sidebar-link>
      <sidebar-link 
        v-if="currentUser.role == 'direksi' || currentUser.role == 'lead_marketing'"
        to="/manage-user">
        <md-icon>manage_accounts</md-icon>
        <p>Manage Users</p>
      </sidebar-link>
      <sidebar-link 
        v-if="currentUser.role == 'direksi'"
        to="/step">
        <md-icon>settings</md-icon>
        <p>Manage Step</p>
      </sidebar-link>
      <sidebar-link 
        to="/help">
        <md-icon>help</md-icon>
        <p>Help & Guide</p>
      </sidebar-link>
      <!--<sidebar-link to="/user">
        <md-icon>person</md-icon>
        <p>User Profile</p>
      </sidebar-link>
      <sidebar-link to="/table">
        <md-icon>content_paste</md-icon>
        <p>Table list</p>
      </sidebar-link>
      <sidebar-link to="/typography">
        <md-icon>library_books</md-icon>
        <p>Typography</p>
      </sidebar-link>
      <sidebar-link to="/icons">
        <md-icon>bubble_chart</md-icon>
        <p>Icons</p>
      </sidebar-link>
      <sidebar-link to="/maps">
        <md-icon>location_on</md-icon>
        <p>Maps</p>
      </sidebar-link>
      <sidebar-link to="/notifications">
        <md-icon>notifications</md-icon>
        <p>Notifications</p>
      </sidebar-link>-->

      <b-button squared class="text-left mt-4 logout-button" @click="logoutModalShow = true">
        <div class="logout-div">
          <md-icon>logout</md-icon>
          <p>Log Out</p>
        </div>
      </b-button>
    </side-bar>

    <b-modal
      ref="modal"
      v-model="logoutModalShow"
      :hide-header-close="true"
      hide-footer
    >
      <template #modal-header>
        <b-row class="w-100 mx-auto">
          <b-col class="p-0">
            <b-alert show variant="danger">
              <b style="font-size: 1.2rem">Sign Out Warning</b>
            </b-alert>
          </b-col>
        </b-row>
      </template>
      
      <div>
        <h5><b>You will be signed out and all unsaved works will be erased. Are you sure?</b></h5>
        <hr>
      </div>
  
      <b-button
        variant="success"
        size="md"
        class="me-2"
        @click="logout()"
      >
        Yes
      </b-button>

      <b-button
        variant="danger"
        size="md"
        @click="logoutModalShow = false"
      >
        Cancel
      </b-button>
    </b-modal>

    <div class="main-panel">
    
      <div
        @click="hideSidebar()"
        class="sidebar-backdrop"
        :style="{
          'z-index': showBackdrop ? '3' : '-99',
          'opacity': showBackdrop ? '1' : '0',
        }"
      >
      </div>

      <top-navbar></top-navbar>

      <!--<fixed-plugin
        :color.sync="sidebarBackground"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin>-->

      <dashboard-content> </dashboard-content>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>

    <md-snackbar :md-position="'center'" :md-duration="5000" :md-active.sync="snackbar.show" md-persistent>
      <span>{{ snackbar.message }}</span>
      <md-button class="md-primary" @click="snackbar.show = false">Close</md-button>
    </md-snackbar>

    <!-- Loading Modal -->
    <b-modal
      ref="modal"
      v-model="showLoadingModal"
      :hide-header-close="true"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <template #modal-header>
        <b-row class="w-100 mx-auto">
          <b-col class="p-0">
            <b-alert class="text-center" show variant="primary">
              <b style="font-size: 1.2rem">Loading, Please Wait</b>
            </b-alert>
          </b-col>
        </b-row>
      </template>

      <div class="text-center">
        <b-spinner class="mb-2" style="width:5rem; height:5rem; font-size:25px" variant="primary" label="Spinning"></b-spinner>
        <h4>This may take seconds...</h4>
      </div>
    </b-modal>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
//import MobileMenu from "@/pages/Layout/MobileMenu.vue";
//import FixedPlugin from "./Extra/FixedPlugin.vue";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    //MobileMenu,
    //FixedPlugin,
  },
  data() {
    return {
      snackbar:{
        message: "",
        show: false,
      },
      logoutModalShow: false,
      showLoadingModal: false,
      showBackdrop: false,
      sidebarBackground: "purple",
      sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg"),
    };
  },
  watch:{
    '$store.state.isLoading': function() {
      const vm = this;
      vm.showLoadingModal = vm.$store.state.isLoading;
    },
    '$store.state.sidebarIsShowed': function() {
      const vm = this;
      vm.showBackdrop = vm.$store.state.sidebarIsShowed;
    }
  },
  computed:{
    currentUser(){
      return this.$store.state.user;
    },
  },
  methods:{
    async logout(){
      const vm = this;

      await vm.axios.post(`${vm.$store.state.baseApiURL}/logout`, {
          refreshToken: vm.$store.state.refreshToken,
          userId: vm.currentUser.id
        })
        .then((response) => {
          vm.$store.state.user = {
            id: null,
            username: null,
            name: null,
            phoneNumber: null,
            role: null
          }

          vm.$store.state.isLoggedIn = false;
          vm.$store.state.accessToken = null;
          vm.$store.state.refreshToken = null;

          window.localStorage.setItem("storagePerumahanUser", "{}");
          window.localStorage.setItem("storagePerumahanAccessToken", null);
          window.localStorage.setItem("storagePerumahanRefreshToken", null);
          window.localStorage.setItem("storagePerumahanIsLoggedIn", false);

          vm.$router.push({ path:'/login' })
        })
        .catch(async err => {
          vm.showSnackbar("Failed to Logout.", err.message);
        })
    },

    showSnackbar(title, message) {
      this.snackbar.message = `${title}`;
      this.snackbar.show = true;
    },
    
    hideSidebar(){
      this.$sidebar.displaySidebar(false);
      this.$store.state.sidebarIsShowed = false;
    }
  },
};
</script>

<style scoped>
  .logout-button{
    background-color:rgba(100,100,100,0.8);
    border: none;
    margin-left: 0px !important;
  }

  .logout-div{
    padding-left: 25px; 
    background-color: transparent;
  }

  .sidebar-backdrop{
    background-color: rgba(0,0,0,0.5);
    transition: 0.1s;
    height: 100%;
    width: 100%;
    position: absolute;
  }
</style>
