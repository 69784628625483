<template>
  <div class="content">
    <div class="md-layout">
      <!-- Customer Statistic -->
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="blue">
            <h4 class="title">Customer Statistics</h4>
            <p class="category">This section is containing total new customer in the date interval</p>
          </md-card-header>
          <md-card-content>
            <div
              class="md-layout"
            >
              <div
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
              >
                <label>Filter From Date</label>
                <md-datepicker v-model="customerFilterDateStart"/>
              </div>
              <div
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
              >
                <label>Filter To Date</label>
                <md-datepicker v-model="customerFilterDateEnd"/>
              </div>
              <div
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
              >
                <label>Filter By Year</label>
                <b-form-select 
                  class="w-100"
                  v-model="customerYearFilter" 
                  :options="yearList"
                />
              </div>
            </div>
            <line-chart 
              style="max-height: 400px" 
              v-if="!customerIsLoading"
              :data="customerChart.data.series"
              :labels="customerChart.data.labels"
              :label="'Total New Customer'"
              :backgroundColor="'rgb(38, 198, 218)'" 
              />
            <center v-else class="text-center my-3">
              <b-spinner style="width:5rem; height:5rem; font-size:25px" variant="success" label="Spinning"></b-spinner>
            </center>
          </md-card-content>
        </md-card>
      </div>

      <!--<div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <chart-card
          v-if="!customerIsLoading"
          :chart-data="customerChart.data"
          :chart-options="customerChart.options"
          :chart-type="'Line'"
          data-background-color="blue"
        >
          <template slot="content">
            <h3 class="title">New Customer Statistics</h3>
            <div
              class="md-layout"
            >
              <div
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
              >
                <label>Filter From Date</label>
                <md-datepicker v-model="customerFilterDateStart"/>
              </div>
              <div
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
              >
                <label>Filter To Date</label>
                <md-datepicker v-model="customerFilterDateEnd"/>
              </div>
              <div
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
              >
                <label>Filter By Year</label>
                <b-form-select 
                  class="w-100"
                  v-model="customerYearFilter" 
                  :options="yearList"
                />
              </div>
            </div>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>access_time</md-icon>
              updated 4 minutes ago
            </div>
          </template>
        </chart-card>
        <center v-else class="text-center my-3">
          <b-spinner style="width:5rem; height:5rem; font-size:25px" variant="success" label="Spinning"></b-spinner>
        </center>
      </div>-->

      <!-- Marketing Statistic -->
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="purple">
            <h4 class="title">Marketing Statistics</h4>
            <p class="category">This section is containing successful transaction count for each marketing in date interval.</p>
          </md-card-header>
          <md-card-content>
            <div
              class="md-layout"
            >
              <div
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
              >
                <label>Filter From Date</label>
                <md-datepicker v-model="marketingFilterDateStart"/>
              </div>
              <div
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
              >
                <label>Filter To Date</label>
                <md-datepicker v-model="marketingFilterDateEnd"/>
              </div>
              <div
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
              >
                <b-input class="w-100" v-model="marketingSearchFilter" placeholder="Search marketing by name..."></b-input>
              </div>
              <div
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
              >
                <b-form-select 
                  class="w-100"
                  placholder="Filter by year..."
                  v-model="marketingYearFilter" 
                  :options="yearList"
                />
              </div>
            </div>
            <b-table  
              v-if="!marketingIsLoading"
              style="max-height: 400px; overflow-y: auto"
              responsive
              hover 
              :items="marketingData" 
              :fields="fields">
            </b-table>
            <center v-else class="text-center my-3">
              <b-spinner style="width:5rem; height:5rem; font-size:25px" variant="success" label="Spinning"></b-spinner>
            </center>
          </md-card-content>
        </md-card>
      </div>

      <!-- Event Statistic -->
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Event Statistics</h4>
            <p class="category">This section is containing total collected phone number count for each event in date interval.</p>
          </md-card-header>
          <md-card-content>
            <div
              class="md-layout"
            >
              <div
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
              >
                <label>Filter From Date</label>
                <md-datepicker v-model="eventFilterDateStart"/>
              </div>
              <div
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
              >
                <label>Filter To Date</label>
                <md-datepicker v-model="eventFilterDateEnd"/>
              </div>
              <div
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
              >
                <label>Filter By Year</label>
                <b-form-select 
                  class="w-100"
                  v-model="eventYearFilter" 
                  :options="yearList"
                />
              </div>
            </div>
            <bar-chart 
              style="max-height: 400px" 
              v-if="!eventIsLoading"
              :data="eventChart.data.series"
              :labels="eventChart.data.labels"
              :label="'Total Collected Phone Numbers'"
              :backgroundColor="'rgb(102, 187, 106)'" 
              />
            <center v-else class="text-center my-3">
              <b-spinner style="width:5rem; height:5rem; font-size:25px" variant="success" label="Spinning"></b-spinner>
            </center>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <md-snackbar :md-position="'center'" :md-duration="5000" :md-active.sync="snackbar.show" md-persistent>
      <span>{{ snackbar.message }}</span>
      <md-button class="md-primary" @click="snackbar.show = false">Close</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import {
  //StatsCard,
  //ChartCard,
  //NavTabsCard,
  //NavTabsTable,
  //OrderedTable,
} from "@/components";

export default {
  components: {
    //StatsCard,
    //ChartCard,
    //NavTabsCard,
    //NavTabsTable,
    //OrderedTable,
  },
  data() {
    return {
      snackbar:{
        message: "",
        show: false,
      },

      customerIsLoading: false,
      eventIsLoading: false,
      marketingIsLoading: false,

      customerFilterDateStart: `${new Date().getFullYear()}-01-01`,
      customerFilterDateEnd: new Date().toLocaleDateString("en-CA"),
      customerYearFilter: null,

      marketingFilterDateStart: `${new Date().getFullYear()}-01-01`,
      marketingFilterDateEnd: new Date().toLocaleDateString("en-CA"),
      marketingSearchFilter: "",
      marketingYearFilter: null,

      eventFilterDateStart: `${new Date().getFullYear()}-01-01`,
      eventFilterDateEnd: new Date().toLocaleDateString("en-CA"),
      eventYearFilter: null,

      fields: [
        {
          key:'id',
          sortable: false
        },
        {
          key: 'name',
          sortable: true
        },
        /*{
          key: 'phone_number',
          sortable: false
        },*/
        {
          key: 'totalSuccessTransaction',
          sortable: true,
          label: "Total Success Transaction"
        },
        {
          key: 'totalHandledTransaction',
          sortable: true,
          label: "Total Received Transaction from Admin"
        },
      ],
    };
  },
  watch:{
    customerFilterDateEnd(newValue, oldValue){
      if(newValue != oldValue){
        if(
          this.customerYearFilter != null &&
          (
            new Date(this.customerFilterDateStart).toLocaleDateString("en-CA") != `${this.customerYearFilter}-01-01`
            || new Date(this.customerFilterDateEnd).toLocaleDateString("en-CA") != `${this.customerYearFilter}-12-31`)
        ){
          this.customerYearFilter = null;
        }

        this.getCustomerStatistic();
      }
    },
    customerFilterDateStart(newValue, oldValue){
      if(newValue != oldValue){
        if(
          this.customerYearFilter != null &&
          (
            new Date(this.customerFilterDateStart).toLocaleDateString("en-CA") != `${this.customerYearFilter}-01-01`
            || new Date(this.customerFilterDateEnd).toLocaleDateString("en-CA") != `${this.customerYearFilter}-12-31`)
        ){
          this.customerYearFilter = null;
        }

        this.getCustomerStatistic();
      }
    },
    marketingFilterDateEnd(newValue, oldValue){
      if(newValue != oldValue){
        if(
          this.marketingYearFilter != null &&
          (
            new Date(this.marketingFilterDateStart).toLocaleDateString("en-CA") != `${this.marketingYearFilter}-01-01`
            || new Date(this.marketingFilterDateEnd).toLocaleDateString("en-CA") != `${this.marketingYearFilter}-12-31`)
        ){
          this.marketingYearFilter = null;
        }

        this.getMarketingStatistic();
      }
    },
    marketingFilterDateStart(newValue, oldValue){
      if(newValue != oldValue){
        if(
          this.marketingYearFilter != null &&
          (
            new Date(this.marketingFilterDateStart).toLocaleDateString("en-CA") != `${this.marketingYearFilter}-01-01`
            || new Date(this.marketingFilterDateEnd).toLocaleDateString("en-CA") != `${this.marketingYearFilter}-12-31`)
        ){
          this.marketingYearFilter = null;
        }

        this.getMarketingStatistic();
      }
    },
    eventFilterDateEnd(newValue, oldValue){
      if(newValue != oldValue){
        if(
          this.eventYearFilter != null &&
          (
            new Date(this.eventFilterDateStart).toLocaleDateString("en-CA") != `${this.eventYearFilter}-01-01`
            || new Date(this.eventFilterDateEnd).toLocaleDateString("en-CA") != `${this.eventYearFilter}-12-31`)
        ){
          this.eventYearFilter = null;
        }

        this.getEventStatistic();
      }
    },
    eventFilterDateStart(newValue, oldValue){
      if(newValue != oldValue){
        if(
          this.eventYearFilter != null &&
          (
            new Date(this.eventFilterDateStart).toLocaleDateString("en-CA") != `${this.eventYearFilter}-01-01`
            || new Date(this.eventFilterDateEnd).toLocaleDateString("en-CA") != `${this.eventYearFilter}-12-31`)
        ){
          this.eventYearFilter = null;
        }

        this.getEventStatistic();
      }
    },

    customerYearFilter(newValue, oldValue){
      if(newValue != oldValue && newValue != null){
        this.customerFilterDateStart = `${newValue.toString()}-01-01`;
        this.customerFilterDateEnd = `${newValue.toString()}-12-31`;
      }
    },
    marketingYearFilter(newValue, oldValue){
      if(newValue != oldValue && newValue != null){
        this.marketingFilterDateStart = `${newValue.toString()}-01-01`;
        this.marketingFilterDateEnd = `${newValue.toString()}-12-31`;
      }
    },
    eventYearFilter(newValue, oldValue){
      if(newValue != oldValue && newValue != null){
        this.eventFilterDateStart = `${newValue.toString()}-01-01`;
        this.eventFilterDateEnd = `${newValue.toString()}-12-31`;
      }
    },
  },
  computed:{
    currentUser(){
      return this.$store.state.user;
    },

    customerStatistics(){
      if(this.customerYearFilter == null || this.$store.state.customerStatistic.length <= 0){
        return this.$store.state.customerStatistic;
      }
      else{
        let currentMonth = new Date(this.$store.state.customerStatistic[0].created_at).getMonth();
        let formattedCustomerData = [];
        let currentTotalCustomer = 0;

        for(let i = 0; i < 12; i++){
          formattedCustomerData.push({
            totalcustomers: 0,
            created_at: null,
            month: `(0) ${this.months[i].name}`
          });
        }

        this.$store.state.customerStatistic.forEach(data => {
          let currentDataMonth = new Date(data.created_at).getMonth();

          if(currentDataMonth == currentMonth){
            currentTotalCustomer = currentTotalCustomer + parseInt(data.totalcustomers);
          }
          else{
            formattedCustomerData[currentDataMonth].totalcustomers = currentTotalCustomer;
            formattedCustomerData[currentDataMonth].month = `(${currentTotalCustomer}) ${this.months[currentDataMonth].name}`;

            currentMonth = currentDataMonth;
            currentTotalCustomer = 0;
          }
        })

        return formattedCustomerData;
      }
    },

    customerChart(){
      const vm = this;

      let customerChart = {
        data: {
          labels: [],
          series: [],
        },
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 0, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      }

      vm.customerStatistics.forEach((item, i) => {
        if(parseInt(item.totalcustomers)+2 > customerChart.options.high){
          customerChart.options.high = parseInt(item.totalcustomers)+2;
        }
        if(vm.customerYearFilter == null){
          customerChart.data.labels.push(new Date(item.created_at).toLocaleDateString("en-CA"));
        }
        else{
          customerChart.data.labels.push(item.month);
        }
        customerChart.data.series.push(item.totalcustomers);
      })

      return customerChart;
    },

    eventStatistic(){
      return this.$store.state.eventStatistic;
    },
    
    eventChart(){
      const vm = this;

      let eventChart = {
        data: {
          labels: [],
          series: [],
        },
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 0, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      }
      
      vm.eventStatistic.forEach(item => {
        if(parseInt(item.totalCollectedPhoneNumber)+2 > eventChart.options.high){
          eventChart.options.high = parseInt(item.totalCollectedPhoneNumber)+2;
        }
        eventChart.data.labels.push(item.name);
        eventChart.data.series.push(item.totalCollectedPhoneNumber);
      })

      return eventChart;
    },


    marketingStatistic(){
      return this.$store.state.marketingStatistic;
    },

    marketingData(){      
      let filteredItems = [];
      const vm = this;

      filteredItems = vm.marketingStatistic;

      if(vm.marketingSearchFilter){
        filteredItems = filteredItems.filter(item => item.name.toLowerCase().includes(vm.marketingSearchFilter.toLowerCase()))
      }

      return filteredItems;
    },

    yearList(){
      let yearList = [
        { value: null, text: "No year filter"}
      ];
      let currentYear = 2018;

      while(currentYear <= new Date().getFullYear()){
        yearList.push({
          value: currentYear.toString(), text: currentYear.toString()
        });
        currentYear++;
      }

      return yearList;
    },

    months(){
      let monthList = [
        {
          name: "Jan",
          maxDays: "31",
          number: "01",
        },
        {
          name: "Feb",
          maxDays: parseInt(new Date().getFullYear())%4 == 0 ? "29" : "28",
          number: "02",
        },
        {
          name: "Mar",
          maxDays: "31",
          number: "03",
        },
        {
          name: "Apr",
          maxDays: "30",
          number: "04",
        },
        {
          name: "May",
          maxDays: "31",
          number: "05",
        },
        {
          name: "Jun",
          maxDays: "30",
          number: "06",
        },
        {
          name: "Jul",
          maxDays: "31",
          number: "07",
        },
        {
          name: "Aug",
          maxDays: "31",
          number: "08",
        },
        {
          name: "Sep",
          maxDays: "30",
          number: "09",
        },
        {
          name: "Oct",
          maxDays: "31",
          number: "10",
        },
        {
          name: "Nov",
          maxDays: "30",
          number: "11",
        },
        {
          name: "Dec",
          maxDays: "31",
          number: "12",
        },
      ];

      return monthList;
    }
  },
  methods:{
    showSnackbar(title, message) {
      this.snackbar.message = `${title} ${message}`;
      this.snackbar.show = true;
    },
    
    async getCustomerStatistic(){
      const vm = this;

      vm.customerIsLoading = true;

      await vm.axios.get(`${vm.$store.state.baseApiURL}/api/statistic/customers?dateStart=${new Date(vm.customerFilterDateStart).toLocaleDateString("en-CA")}&dateEnd=${new Date(vm.customerFilterDateEnd).toLocaleDateString("en-CA")}`, 
      {
        data:{},
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        },
        timeout: 10000
      })
      .then((response) => {
        if(Array.isArray(response.data)){        
          vm.$store.state.customerStatistic = response.data.sort((a,b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
        }
        else{
          vm.$store.state.customerStatistic = [];
        }
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.getCustomerStatistic();
          }
        }
        else{
          vm.showSnackbar("Failed to Get Customer Statistic Data", err.message);
        }
      })

      vm.customerIsLoading = false;
    },

    async getMarketingStatistic(){
      const vm = this;

      vm.marketingIsLoading = true;
      await vm.axios.get(`${vm.$store.state.baseApiURL}/api/statistic/users?dateStart=${new Date(vm.marketingFilterDateStart).toLocaleDateString("en-CA")}&dateEnd=${new Date(vm.marketingFilterDateEnd).toLocaleDateString("en-CA")}`, 
      {
        data:{},
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        },
        timeout: 10000
      })
      .then((response) => {
        if(Array.isArray(response.data)){        
          vm.$store.state.marketingStatistic = response.data.sort((a,b) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime());
        }
        else{
          vm.$store.state.marketingStatistic = [];
        }
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.getMarketingStatistic();
          }
        }
        else{
          vm.showSnackbar("Failed to Get Marketing Statistic Data", err.message);
        }
      })

      vm.marketingIsLoading = false;
    },

    async getEventStatistic(){
      const vm = this;

      vm.eventIsLoading = true;
      
      await vm.axios.get(`${vm.$store.state.baseApiURL}/api/events/get?dateStart=${new Date(vm.eventFilterDateStart).toLocaleDateString("en-CA")}&dateEnd=${new Date(vm.eventFilterDateEnd).toLocaleDateString("en-CA")}`, 
      {
        data:{},
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        },
        timeout: 10000
      })
      .then((response) => {
        if(Array.isArray(response.data)){        
          vm.$store.state.eventStatistic = response.data.sort((a,b) => new Date(a.date_start).getTime() - new Date(b.date_start).getTime());
        }
        else{
          vm.$store.state.eventStatistic = [];
        }
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.getEventStatistic();
          }
        }
        else{
          vm.showSnackbar("Failed to Get Events Data", err.message);
        }
      })
      vm.eventIsLoading = false;
    },
  },
  async mounted(){
    const vm = this;

    if(vm.currentUser.role !== "direksi" && vm.currentUser.role !== "lead_marketing"){
      vm.$router.push({ path:'/login' });
    }
    else{
      await this.getCustomerStatistic();
      await this.getMarketingStatistic();
      await this.getEventStatistic();
      // vm.customerFilterDateStart = `${new Date().getFullYear()}-01-01`;
      // vm.marketingFilterDateStart = `${new Date().getFullYear()}-01-01`;
      // vm.eventFilterDateStart = `${new Date().getFullYear()}-01-01`;
    }
  }
};
</script>
