<template>
  <md-card>
    <md-card-header data-background-color="purple">
      <h3 class="title">
        Active Transaction
        <br>
        <span  
          v-if="activeTransaction.is_from_event == 1"
          class="p-2"
          style="font-size:1rem; background-color: #fd7e14; border-radius: 5px; font-weight: 500">
          FROM {{ eventData.name }} PLACED IN {{ eventData.place }}
        </span>
      </h3>
    </md-card-header>

    <md-card-content v-if="activeTransaction.id">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-33">
          <md-field>
            <label style="font-size:14px">Handled By</label>
            <md-input v-model="activeTransaction.marketing_name" disabled></md-input>
          </md-field>
        </div>

        <div 
          v-if="activeTransaction" 
          class="md-layout-item md-small-size-100 md-size-33" >
          <md-field>
            <label>Created At</label>
            <md-input 
              v-model="activeTransaction.createdAtFormatted" disabled>
            </md-input>
          </md-field>
        </div>

        <div 
          v-if="activeTransaction" 
          class="md-layout-item md-small-size-100 md-size-33">
          <md-field>
            <label>Last Updated</label>
            <md-input v-model="activeTransaction.updatedAtFormatted" disabled></md-input>
          </md-field>
        </div>

        <b-row class="w-100 m-0">
          <b-col cols="12" sm="6" md="4">
            <md-button
              class="text-left w-100"
              :class="{ 'md-success': !addProgressCollapseShow, 'md-danger': addProgressCollapseShow }"
              @click="addProgressCollapseShow = !addProgressCollapseShow">
              <span style="font-size:14px">
                {{ addProgressCollapseShow ? "Cancel" : "Add Progress" }}
                <b-icon class="ms-2" style="font-size:18px" :icon="addProgressCollapseShow ? 'x-circle-fill':'plus-circle-fill'" />
              </span>
            </md-button>
          </b-col>
          <b-col cols="12" sm="6" md="4">
            <md-button 
              class="text-left md-info w-100"
              @click="stepCollapseShow = !stepCollapseShow">
              <span style="font-size:14px">
                Show All Progress
                <md-icon>arrow_circle_down</md-icon>
              </span>
            </md-button>
          </b-col>
          <b-col cols="12" sm="6" md="4">
            <md-button 
              class="text-left md-danger w-100"
              @click="finishTransactionModal = true">
              <span style="font-size:14px">
                Finish Transaction
              </span>
            </md-button>
          </b-col>
        </b-row>

        <b-row class="w-100 p-0 m-0" :no-gutters="true">
          <b-col class="p-0 my-2" cols="12">
            <b-collapse v-model="addProgressCollapseShow" accordion="transaction-accordion">
              <b-card>
                <h4 style="font-weight:700">Add Progress</h4>
                <form ref="form" @submit.stop.prevent="addProgressCustomer">
                  <b-form-group
                    class="mx-0"
                  >
                    <label>Pick the Progress Step</label>
                    <b-form-select 
                      class="w-100"
                      v-model="newProgressData.step_id" 
                      :options="stepOptions"
                      required>
                    </b-form-select>
                  </b-form-group>

                  <b-form-group
                    class="my-2"
                  >
                      <label>Progress Notes</label>
                      <md-field>
                        <md-textarea v-model="newProgressData.notes" md-autogrow></md-textarea>
                      </md-field>
                  </b-form-group>

                  <md-button
                    type="submit"
                    class="me-2 md-success"
                  >
                    Submit
                  </md-button>

                  <md-button
                    class="md-danger"
                    size="md"
                    @click="addProgressCollapseShow = false"
                  >
                    Cancel
                  </md-button>
                </form>
              </b-card>
            </b-collapse>
          </b-col>

          <b-col class="p-0" cols="12">
            <b-collapse v-model="stepCollapseShow" accordion="transaction-accordion">
              <b-card style="max-height: 500px; overflow-y:auto" v-if="activeTransactionProgresses.length > 0">
                <b-alert 
                  v-for="(progress, i) in activeTransactionProgresses[0]" 
                  :key="i"
                  show 
                  :variant="i == 0 ? 'info' : 'secondary'"
                  :style="{ 'background-color': i == 0 ? 'rgba(0, 123, 255, 0.7)': 'rgba(253, 126, 20, 0.8)' }">
                  <b-row>
                    <b-col cols="12">
                      <b style="font-size:1rem">
                        {{ progress.step_name.toUpperCase() }}
                        <span style="float:right;">
                          {{ new Date(progress.created_at).toLocaleDateString() }}
                        </span>
                      </b>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col cols="12" sm="9">
                      <b style="font-weight:500; font-size: 0.8rem">
                        Notes : {{ progress.notes }}
                      </b>
                      
                    </b-col>
                    <b-col cols="12" sm="3">
                      <b-button
                        size="sm"
                        variant="danger"
                        style="float: right; border: solid 1px white"
                        v-if="progress.step_id > 4"
                        @click="deleteProgress(progress)"
                      >
                        <b-icon icon="trash-fill"></b-icon>
                      </b-button>
                      <b-button
                        v-if="true/*currentUser.role =='marketing' && progress.step_name.toLowerCase() != 'pending' && progress.step_name.toLowerCase() != 'deal'*/"
                        :class="{
                          'me-2': progress.step_id <= 4 ? false : true
                        }"
                        size="sm"
                        variant="warning"
                        style="float: right; border: solid 1px white" 
                        @click="editProgress(progress)"
                      >
                        <b-icon icon="pencil-square"></b-icon>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-alert>
              </b-card>
              <b-card class="text-center" v-else>
                <h3>No Progress</h3>
              </b-card>
            </b-collapse>
          </b-col>
        </b-row>
      </div>
    </md-card-content>

    <md-card-content class="text-center" v-else>
      <h3>No Active Transaction</h3>
    </md-card-content>

    <b-modal
      v-model="deleteProgressModalShow"
      :hide-header-close="true"
      header-variant="danger"
      hide-footer
    >
      <template #modal-header>
        <b-row class="w-100 mx-auto">
          <b-col class="p-0">
            <b-alert show variant="danger">
              <b style="font-size: 1.2rem">Delete Progress</b>
            </b-alert>
          </b-col>
        </b-row>
      </template>

      <h5>
        This progress will be deleted. Are you sure?
      </h5>
      <b-row>
        <b-col>
          <b-button
            class="me-2"
            size="sm"
            variant="danger"
            @click="deleteProgressCustomer()"
          >
            Delete
          </b-button>
          <b-button
            size="sm"
            variant="success"
            @click="deleteProgressModalShow = false;"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      v-if="pickedProgressData"
      v-model="editProgressModalShow"
      :hide-header-close="true"
      header-variant="danger"
      hide-footer
    >
      <template #modal-header>
        <b-row class="w-100 mx-auto">
          <b-col class="p-0">
            <b-alert show variant="warning">
              <b style="font-size: 1.2rem">Edit Progress</b>
            </b-alert>
          </b-col>
        </b-row>
      </template>

      <b-card>
        <form ref="form" @submit.stop.prevent="editProgressCustomer">
          <b-form-group
            v-if="pickedProgressData.step_name != 'pending' && pickedProgressData.step_name != 'negotiation' && pickedProgressData.step_name != 'deal'"
            label="Progress Step"
          >
              <b-form-select 
                class="w-100 "
                v-model="pickedProgressData.step_id" 
                :options="stepOptions"
                required>
              </b-form-select>
          </b-form-group>

          <b-form-group
            class="my-2"
            label="Progress Notes"
          >
              <md-field>
                <md-textarea v-model="pickedProgressData.notes" md-autogrow></md-textarea>
              </md-field>
          </b-form-group>
          <md-button
            type="submit"
            class="me-2 md-success"
          >
            Save
          </md-button>

          <md-button
            class="md-danger"
            size="md"
            @click="editProgressModalShow = false"
          >
            Cancel
          </md-button>
        </form>
      </b-card>
    </b-modal>

    <b-modal
      v-model="finishTransactionModal"
      :hide-header-close="true"
      header-variant="danger"
      hide-footer
    >
      <template #modal-header>
        <b-row class="w-100 mx-auto">
          <b-col class="p-0">
            <b-alert show variant="danger">
              <b style="font-size: 1.2rem">Finish Transaction</b>
            </b-alert>
          </b-col>
        </b-row>
      </template>

      <h5>
        This transaction will be finished and cannot be undone. Are you sure?
      </h5>
      <b-row>
        <b-col>
          <b-button
            class="me-2"
            size="sm"
            variant="danger"
            @click="finishTransaction()"
          >
            Finish
          </b-button>
          <b-button
            size="sm"
            variant="success"
            @click="finishTransactionModal = false;"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <md-snackbar :md-position="'center'" :md-duration="5000" :md-active.sync="snackbar.show" md-persistent>
      <span>{{ snackbar.message }}</span>
      <md-button class="md-primary" @click="snackbar.show = false">Close</md-button>
    </md-snackbar>
  </md-card>
</template>
<script>
export default {
  name: "transaction-history",
  props: {
    transactionData: [],
    data: {},
    progressData: {},
    event: [],
  },
  data() {
    return {
      snackbar:{
        message: "",
        show: false,
      },
      handledBy: "Marketing 1",
      stepCollapseShow: true,
      addProgressCollapseShow: false,
      newProgressData:{
        step: "",
        notes: "",
      },
      pickedProgressData:{
        step: "",
        notes: "",
      },
      isEditProgress: false,
      deleteProgressModalShow: false,
      editProgressModalShow: false,
      stepOptions:[],
      activeTransactionIsExist: false,
      finishTransactionModal: false,
      //activeTransaction: {},
    };
  },
  watch:{
    data(){
      const vm = this;
      
      /*
      if(vm.data && vm.data.length > 0){
        vm.activeTransaction = vm.data[0];
        vm.activeTransaction.createdAtFormatted = `${new Date(vm.activeTransaction.created_at).toLocaleDateString()} | ${new Date(vm.activeTransaction.created_at).toLocaleTimeString()}`;
        vm.activeTransaction.updatedAtFormatted = `${new Date(vm.activeTransaction.updated_at).toLocaleDateString()} | ${new Date(vm.activeTransaction.updated_at).toLocaleTimeString()}`;
      }
      else{
        vm.activeTransaction = {}
      }*/
    }
  },
  computed:{
    currentUser(){
      return this.$store.state.user;
    },
    currentCustomerId(){
      return this.$route.params.id;
    },
    steps(){
      const vm = this;
      return vm.$store.state.steps;
    },
    activeTransactionProgresses(){
      const vm = this;

      if(vm.progressData){
        return vm.progressData
      }
      else{
        return []
      }
    },
    activeTransaction(){
      const vm = this;
      let activeTransaction;
      
      if(vm.data && vm.data.length > 0){
        activeTransaction = vm.data[0];
        activeTransaction.createdAtFormatted = `${new Date(activeTransaction.created_at).toLocaleDateString()} | ${new Date(activeTransaction.created_at).toLocaleTimeString()}`;
        activeTransaction.updatedAtFormatted = `${new Date(activeTransaction.updated_at).toLocaleDateString()} | ${new Date(activeTransaction.updated_at).toLocaleTimeString()}`;
      }
      else{
        activeTransaction = {}
      }

      return activeTransaction;
    },
    eventData(){
      const vm = this;

      if(vm.event && vm.event.length > 0){
        return vm.event[0]  ;
      }
      else{
        return {};
      }
    }
  },
  methods:{
    editProgress(progress){
      const vm = this;

      vm.pickedProgressData = JSON.parse(JSON.stringify(progress));
      vm.editProgressModalShow = true;
    },

    deleteProgress(progress){
      const vm = this;

      vm.pickedProgressData = JSON.parse(JSON.stringify(progress));
      vm.deleteProgressModalShow = true;
    },
    
    async finishTransaction(){
      const vm = this;

      vm.finishTransactionModal = false;
      vm.$store.state.isLoading = true;

      await vm.axios.post(`${vm.$store.state.baseApiURL}/api/transactions/progress/post`, {
        step_id: 2,
        notes: "Transaction Finished.",
        transaction_id: vm.activeTransaction.id,
        customer_id: vm.currentCustomerId
      },
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.showSnackbar("Finish Transaction Success!", "");
        vm.$emit('refreshCustomerDetail')
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.finishTransaction();
          }
        }
        else{
          vm.showSnackbar("Failed to Finish Transaction.", err.response.data);
        }
      })

      vm.$store.state.isLoading = false;
    },

    async getStepsData(){
      const vm = this;

      await vm.axios.get(`${vm.$store.state.baseApiURL}/api/steps/get`,
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.$store.state.steps = response.data;
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.getStepsData();
          }
        }
        else{
          vm.showSnackbar("Failed to Get Steps Data", err.message);
        }
      })

      vm.stepOptions = [];
      vm.steps.forEach(step => {
        if(step.name != 'pending' && step.name != 'negotiation' && step.name != 'deal'){
          vm.stepOptions.push({ value:step.id, text:step.name.toUpperCase() });
        }
      });
    },
    
    async addProgressCustomer(){
      const vm = this;

      vm.$store.state.isLoading = true;

      await vm.axios.post(`${vm.$store.state.baseApiURL}/api/transactions/progress/post`, {
        step_id: vm.newProgressData.step_id,
        notes: vm.newProgressData.notes,
        transaction_id: vm.activeTransaction.id,
        customer_id: vm.currentCustomerId
      },
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.showSnackbar("Add Progress Success!", "");
        vm.$emit('refreshCustomerDetail')
        vm.addProgressCollapseShow = false;
        vm.stepCollapseShow = true;
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.addProgressCustomer();
          }
        }
        else{
          vm.showSnackbar("Failed to Add Progress.", err.response.data);
          vm.stepCollapseShow = true;
        }
      })

      vm.$store.state.isLoading = false;
    },

    async editProgressCustomer(){
      const vm = this;

      vm.$store.state.isLoading = true;

      await vm.axios.put(`${vm.$store.state.baseApiURL}/api/transactions/progress/update/${vm.pickedProgressData.id}`, {
        step_id: vm.pickedProgressData.step_id,
        notes: vm.pickedProgressData.notes,
        transaction_id: vm.activeTransaction.id,
        customer_id: vm.currentCustomerId
      },
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.showSnackbar("Update Progress Success!", "");
        vm.$emit('refreshCustomerDetail')
        vm.editProgressModalShow = false;
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.editProgressCustomer();
          }
        }
        else{
          vm.showSnackbar("Failed to Update the Progress.", err.response.data);
          vm.editProgressModalShow = false;
        }
      })

      vm.$store.state.isLoading = false;
    },

    async deleteProgressCustomer(){
      const vm = this;

      vm.$store.state.isLoading = true;

      await vm.axios.delete(`${vm.$store.state.baseApiURL}/api/transactions/progress/delete/${vm.pickedProgressData.id}`,
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      },
      {
        customer_id: vm.currentCustomerId
      })
      .then((response) => {
        vm.showSnackbar("Delete Progress Success!", "");
        vm.$emit('refreshCustomerDetail')
        vm.deleteProgressModalShow = false;
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.deleteProgressCustomer();
          }
        }
        else{
          vm.deleteProgressModalShow = false;
          vm.showSnackbar("Failed to Delete the Progress.", err.response.data);
        }
      })

      vm.$store.state.isLoading = false;
    },

    showSnackbar(title, message) {
      this.snackbar.message = `${title}`;
      this.snackbar.show = true;
    },
  },
  mounted(){
    const vm = this;

    vm.getStepsData();
  }
};
</script>
<style>

  select {
    border: none;
    border-bottom: 1px solid #ced4da;
    margin: 2px !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
  }

  input{
    border: none;
    border-bottom: 1px solid #ced4da;
    margin: 2px !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
  }

  textarea{
    border: none;
    border-bottom: 1px solid #ced4da;
    margin: 2px !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
  }
</style>
