<template>
  <router-view>
    <b-modal
      ref="modal"
      v-model="showLoadingModal"
      :hide-header-close="true"
      no-close-on-backdrop
      no-close-on-esc
      header-variant="success"
      hide-footer
    >
      <template #modal-header>
        <b-row class="w-100 mx-auto">
          <b-col class="p-0">
            <b-alert show variant="success">
              <b style="font-size: 1.2rem">Loading</b>
            </b-alert>
          </b-col>
        </b-row>
      </template>

      Test
    </b-modal>
  </router-view>
</template>

<script>
export default {
  data(){
    return{
      showLoadingModal: true
    }
  }
};
</script>

<style>
  html, body {
    height: 100%;
  }
</style>
