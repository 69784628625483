import DropDown from "./components/Dropdown.vue";
import TransactionHistory from "./components/Transaction/TransactionHistory";
import ActiveTransaction from "./components/Transaction/ActiveTransaction";
import ListCollectedNumber from "./components/Event/ListCollectedNumber";
import BarChart from "./components/ChartJs/BarChart";
import LineChart from "./components/ChartJs/LineChart";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component("drop-down", DropDown);
    Vue.component("transaction-history", TransactionHistory);
    Vue.component("active-transaction", ActiveTransaction);
    Vue.component("list-collected-number", ListCollectedNumber);
    Vue.component("bar-chart", BarChart);
    Vue.component("line-chart", LineChart);
  },
};

export default GlobalComponents;
