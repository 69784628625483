<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Manage Users</h4>
            <p class="category">This page is belongs to direction due to its safety level</p>
          </md-card-header>

          <md-card-content 
            v-if="dataIsLoading"
            class="text-center">
            <b-spinner style="width:5rem; height:5rem; font-size:25px" variant="success" label="Spinning"></b-spinner>
          </md-card-content>

          <md-card-content
            v-else>
            <b-row>
              <b-col sm="12"  md="3"></b-col>
              <b-col sm="12"  md="3"></b-col>
              <b-col sm="12" md="3" class="text-right">
                <md-button 
                  class="md-success w-100"
                  @click="addModalShow = true; showTelegramWarningModal = true;">
                  <md-icon>library_add</md-icon>
                  New User
                </md-button>
              </b-col>
              <b-col sm="12" md="3" class="text-right">
                <md-button 
                  class="w-100"
                  :class="{ 'md-info': !dataFilterShow, 'md-danger': dataFilterShow }"
                  @click="dataFilterShow = !dataFilterShow">
                  <md-icon>{{ dataFilterShow == true ? 'arrow_circle_up' : 'arrow_circle_down' }}</md-icon>
                  {{ dataFilterShow == true ? 'Hide Data Filter' : 'Show Data Filter' }}
                </md-button>
              </b-col>
            </b-row>

            <b-collapse v-model="dataFilterShow">
              <b-row>
                <b-col cols="12" md="3">
                  <md-field>
                    <label>Search</label>
                    <md-input v-model="searchText" placeholder="Search by name"></md-input>
                    <b-button
                      variant="secondary"
                      size="sm"
                      @click="getData()"
                      >
                      <md-icon class="text-white">search</md-icon>
                    </b-button>
                  </md-field>
                </b-col>

                <b-col cols="12" md="2">
                  <md-field>
                    <b-form-select 
                      class="w-100"
                      :disabled="currentUser.role == 'lead_marketing'"
                      v-model="roleFilter" 
                      :options="roleOptions"
                      style="border: none">
                    </b-form-select>
                  </md-field>
                </b-col>

                <b-col cols="12" md="2">
                  <md-field>
                    <b-form-select 
                      class="w-100"
                      v-model="statusFilter" 
                      :options="statusOptions"
                      style="border: none">
                    </b-form-select>
                  </md-field>
                </b-col>

                <b-col cols="12" md="3">
                  <md-field>
                    <b-form-select 
                      class="w-100"
                      v-model="perPage" 
                      :options="perPageOptions"
                      style="border: none">
                    </b-form-select>
                  </md-field>
                </b-col>

                <b-col sm="12" md="2">
                  <md-button 
                    class="md-primary w-100"
                    @click="resetFilter()">
                    <md-icon>refresh</md-icon>
                    Reset Filter
                  </md-button>
                </b-col>
              </b-row>
            </b-collapse>

            <b-row>
              
            </b-row>
            <b-table 
              style="max-height: 400px; overflow-y: auto"
              responsive
              hover 
              :items="filteredItems" 
              :fields="fields">

              <template #cell(role)="data">
                {{ data.value.toUpperCase() }}
              </template>

              <template #cell(status)="data">
                <span 
                  v-if="data.value == 1" 
                  class="py-1 px-2 text-white"
                  style="background-color:#28a745; border-radius:5px; font-weight: 550; white-space: nowrap">
                  Active
                </span>
                <span 
                  v-else
                  class="py-1 px-2 text-white"
                  style="background-color:#dc3545 ; border-radius:5px; font-weight: 550; white-space: nowrap">
                  Not Active
                </span>
              </template>

              <template #cell(action)="data">
                <b-button
                  variant="primary"
                  class="ms-2 mt-1"
                  size="sm"
                  @click="detailUser(data.item.id)"
                  >
                  <md-icon class="text-white">launch</md-icon>
                </b-button>
                <b-button
                  v-if="currentUser.role == 'direksi' || currentUser.role == 'lead_marketing'"
                  variant="warning"
                  size="sm"
                  class="ms-2 mt-1"
                  @click="pickData(data.item); editModalShow = true; showTelegramWarningModal = true;"
                  >
                  <md-icon>edit</md-icon>
                </b-button>
                <!--<b-button
                  v-if="currentUser.role == 'direksi'"
                  variant="danger"
                  size="sm"
                  class="ms-2 mt-1"
                  >
                  <md-icon class="text-white">delete </md-icon>
                </b-button>-->
              </template>

            </b-table>
          </md-card-content>

          <md-card-content>
            <b-row>
              <b-col>
                <b-button
                  :disabled="previousPageDisabled"
                  variant="primary"
                  size="sm"
                  @click="changePage('prev')"
                  >
                  <md-icon class="text-white">arrow_left</md-icon>
                </b-button>
                
                <b-button
                  disabled
                  variant="success"
                  size="sm"
                  class="mx-2 px-3"
                  >
                  <span style="font-size:1.05rem; font-weight:700">{{ currentPage }}</span>
                </b-button>

                <b-button
                  :disabled="nextPageDisabled"
                  variant="primary"
                  size="sm"
                  @click="changePage('next')"
                  >
                  <md-icon class="text-white">arrow_right</md-icon>
                </b-button>
              </b-col>
            </b-row>
          </md-card-content>
        </md-card>

        <b-modal
          ref="modal"
          v-model="addModalShow"
          :hide-header-close="true"
          header-variant="success"
          hide-footer
        >
          <template #modal-header>
            <b-row class="w-100 mx-auto">
              <b-col class="p-0">
                <b-alert show variant="success">
                  <b style="font-size: 1.2rem">Add New User</b>
                </b-alert>
              </b-col>
            </b-row>
          </template>

          <form ref="form" @submit.stop.prevent="addUser">
            <b-form-group
            >
              <label for="name-input"><b>Name</b></label>
              <b-form-input
                id="name-input"
                v-model="newData.name"
                placeholder="Input the name here..."
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="my-3"
            >
            <label for="phone-input"><b>Phone Number (13 Digit Max)</b></label>
              <b-form-input
                id="phone-input"
                v-model="newData.phone_number"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "13"
                placeholder="Do not use +62 etc. Example: 08181133333"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="my-3"
            >
            <label for="username"><b>Username</b></label>
              <b-form-input
                id="username"
                v-model="newData.username"
                placeholder="Cannot use registered usernames"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="my-3"
            >
            <label for="password"><b>Password</b></label>
              <b-form-input
                id="password"
                v-model="newData.password"
                placeholder="Input the password here..."
                required
              ></b-form-input>
            </b-form-group>

            <div class="my-3">
              <label for="password"><b>Role</b></label>
              <b-form-select 
                  class="w-100 "
                  v-model="newData.role" 
                  :options="newRoleOptions"
                  required>
              </b-form-select>
            </div>

            <b-form-group
              class="my-3"
            >
              <label for="telegram-input"><b>Telegram ID (For Notification)</b></label>
              <b-form-input
                id="telegram-input"
                v-model="newData.telegram_id"
                placeholder="Input the telegram ID here..."
                required
              ></b-form-input>
            </b-form-group>

            <div
              class="my-3"
            >
              <label for="password"><b>Status</b></label>
              <b-form-select 
                class="w-100 "
                v-model="newData.status" 
                :options="newStatusOptions"
                required>
              </b-form-select>
            </div>
            <hr>
            <b-button
              variant="success"
              size="md"
              type="submit"
              class="me-2"
            >
              Submit
            </b-button>

            <b-button
              variant="danger"
              size="md"
              @click="resetAddModal()"
            >
              Cancel
            </b-button>
          </form>
        </b-modal>

        <b-modal
          ref="modal"
          v-model="editModalShow"
          :hide-header-close="true"
          header-variant="success"
          hide-footer
        >
          <template #modal-header>
            <b-row class="w-100 mx-auto">
              <b-col class="p-0">
                <b-alert show variant="warning">
                  <b style="font-size: 1.2rem">Edit User</b>
                </b-alert>
              </b-col>
            </b-row>
          </template>

          <form ref="form" @submit.stop.prevent="editUser">
            <b-form-group
            >
              <label for="name-input"><b>Name</b></label>
              <b-form-input
                id="name-input"
                v-model="pickedData.name"
                placeholder="Input the name here..."
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="my-3"
            >
            <label for="phone-input"><b>Phone Number (13 Digit Max)</b></label>
              <b-form-input
                id="phone-input"
                v-model="pickedData.phone_number"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "13"
                placeholder="Do not use +62 etc. Example: 08181133333"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="my-3"
            >
            <label for="username"><b>Username</b></label>
              <b-form-input
                id="username"
                v-model="pickedData.username"
                placeholder="Cannot use registered usernames"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="my-3"
            >
            <label for="password"><b>Password</b></label>
              <b-form-input
                id="password"
                v-model="pickedData.password"
                placeholder="Input the password here..."
                required
              ></b-form-input>
            </b-form-group>

            <div
              class="my-3"
            >
              <label for="password"><b>Role</b></label>
              <b-form-select 
                class="w-100 "
                v-model="pickedData.role" 
                :options="newRoleOptions"
                required>
              </b-form-select>
            </div>

            <b-form-group
              class="my-3"
            >
              <label for="telegram-input"><b>Telegram ID (For Notification)</b></label>
              <b-form-input
                id="telegram-input"
                v-model="pickedData.telegram_id"
                placeholder="Input the telegram ID here..."
                required
              ></b-form-input>
            </b-form-group>

            <div
              class="my-3"
            >
              <label for="password"><b>Status</b></label>
              <b-form-select 
                class="w-100 "
                v-model="pickedData.status" 
                :options="newStatusOptions"
                required>
              </b-form-select>
            </div>
            <hr>
            <b-button
              variant="success"
              size="md"
              type="submit"
              class="me-2"
            >
              Submit
            </b-button>

            <b-button
              variant="danger"
              size="md"
              @click="editModalShow = false;"
            >
              Cancel
            </b-button>
          </form>
        </b-modal>
      </div>
    </div>

    <!-- Telegram ID Warning Modal -->
    <b-modal
      ref="modal"
      v-model="showTelegramWarningModal"
      :hide-header-close="true"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <template #modal-header>
        <b-row class="w-100 mx-auto">
          <b-col class="p-0">
            <b-alert class="text-center" show variant="danger">
              <b style="font-size: 1.2rem">WARNING!</b>
            </b-alert>
          </b-col>
        </b-row>
      </template>

      <div class="text-center">
        <h4>
          Make sure the account has an active <b>Telegram ID</b> and already start the @perumahan_bot in 
          their Telegram App before edit / add new account!
          <br>(see <a to="/help">Help & Guide </a>page) 
        </h4>
        <md-button class="md-primary w-100" @click="showTelegramWarningModal = false"><b>OK</b></md-button>
      </div>
    </b-modal>

    <md-snackbar :md-position="'center'" :md-duration="5000" :md-active.sync="snackbar.show" md-persistent>
      <span>{{ snackbar.message }}</span>
      <md-button class="md-primary" @click="snackbar.show = false">Close</md-button>
    </md-snackbar>
  </div>
</template>

<script>

export default {
  data(){
    return{
      snackbar:{
        message: "",
        show: false,
      },
      
      dataIsLoading: false,
      dataFilterShow: true,

      showTelegramWarningModal: false,
      addModalShow: false,
      editModalShow: false,
      newData:{
        name: "",
        phoneNumber: "",
        role: "marketing",
        username: "",
        password: "",
        status: 1,
      },
      pickedData:{},

      newStatusOptions:[
          { value: 1, text: 'Active' },
          { value: 0, text: 'Not Active' },
      ],

      roleFilter: "all role",
      statusFilter: null,
      roleOptions:[
          { value: "all role", text: 'All Role' },
          { value: "marketing", text: 'Marketing' },
          { value: "admin", text: 'Admin' },
          { value: "lead_marketing", text: 'Lead Marketing' },
          { value: "direksi", text: 'Direksi' }
      ],
      statusOptions:[
          { value: null, text: 'All Status' },
          { value: 1, text: 'Active' },
          { value: 0, text: 'Not Active' },
      ],

      currentPage: 1,
      perPage: 25,
      perPageOptions:[
          { value: 25, text: '25 data each page' },
          { value: 50, text: '50 data each page' },
          { value: 100, text: '100 data each page' },
          { value: 200, text: '200 data each page' }
      ],
      fields: [
        {
          key:'id',
          sortable: false
        },
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'phone_number',
          sortable: false
        },
        {
          key: 'role',
          sortable: false
        },
        {
          key: 'username',
          sortable: true
        },
        {
          key: 'password',
          sortable: false
        },
        {
          key: 'telegram_id',
          label: "Telegram ID",
          sortable: false
        },
        {
          key: 'status',
          sortable: false
        },
        {
          key: 'action',
          label: 'Action'
        }
      ],
      items: [],
      searchText: "",
    }
  },
  components: {
  },
  computed:{
    currentUser(){
      return this.$store.state.user;
    },
    users(){
      let users = this.$store.state.users;
      
      return users;
    },
    totalRows(){
      let totalRow = Math.ceil(this.filteredItems.length/this.perPage)
      return totalRow;
    },
    filteredItems(){      
      let filteredItems = [];
      const vm = this;

      filteredItems = vm.users;

      return filteredItems;
    },
    
    newRoleOptions(){
      let options = [
        { value: "marketing", text: 'Marketing' },
        { value: "admin", text: 'Admin' },
        { value: "lead_marketing", text: 'Lead Marketing' },
        { value: "direksi", text: 'Direksi' }
      ];

      if(this.currentUser.role == "lead_marketing"){
        options = [
          { value: "marketing", text: 'Marketing' }
        ];
      }

      return options;
    },
    
    nextPageDisabled(){
      const vm = this;

      if(vm.perPage <= vm.filteredItems.length){
        return false;
      }
      else{
        return true;
      }
    },
    previousPageDisabled(){
      const vm = this;

      if(vm.currentPage > 1){
        return false;
      }
      else{
        return true;
      }
    },
  },
  watch:{
    perPage(newValue, oldValue) {
      if(newValue != oldValue){
        const vm = this;

        vm.currentPage = 1;
        vm.getData();
      }
    },
    statusFilter(newValue, oldValue) {
      if(newValue != oldValue){
        const vm = this;

        vm.currentPage = 1;
        vm.getData();
      }
    },
    roleFilter(newValue, oldValue) {
      if(newValue != oldValue){
        const vm = this;

        vm.currentPage = 1;
        vm.getData();
      }
    },
  },
  methods:{
    changePage(condition){
      const vm = this;

      if(condition == 'next'){
        vm.currentPage++;
        vm.getData();
      }
      else{
        vm.currentPage--;
        vm.getData();
      }
    },

    resetFilter(){
      const vm = this;

      vm.searchText = "";
      vm.roleFilter = vm.currentUser.role == "lead_marketing" ? "marketing" : "all role";
      vm.statusFilter = null;

      vm.getData();
    },

    getData(){
      const vm = this;
      let url = `${vm.$store.state.baseApiURL}/api/users/get?page=${vm.currentPage}&size=${vm.perPage}`;

      if(vm.searchText != "" && vm.searchText){
        url = url + `&name=${vm.searchText}`;
      }

      if(vm.roleFilter != "all role"){
        url = url + `&role=${vm.roleFilter}`;
      }

      if(vm.statusFilter != null){
        url = url + `&status=${vm.statusFilter}`;
      }

      vm.requestDataAPI(url);
    },

    async requestDataAPI(url){
      const vm = this;

      vm.dataIsLoading = true;

      await vm.axios.get(url, 
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        if(Array.isArray(response.data)){        
          vm.$store.state.users = response.data;
        }
        else{
          vm.$store.state.users = [];
        }
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.requestDataAPI(url);
          }
        }
        else{
          vm.showSnackbar("Failed to Get Users Data", err.message);
        }
      })
      
      vm.dataIsLoading = false;
    },

    detailUser(userId){
      const vm = this;

      vm.$router.push(`/manage-user/${userId}`);
    },
    resetAddModal(){
      const vm = this;

      vm.newData.name = "";
      vm.newData.phone_number = "";
      vm.newData.username = "";
      vm.newData.password = "";
      vm.newData.telegram_id = "";

      vm.addModalShow = false;
    },

    pickData(data){
      const vm = this;

      vm.pickedData = JSON.parse(JSON.stringify(data));
    },

    async addUser(event) {
      const vm = this;

      event.preventDefault()
      vm.$store.state.isLoading = true;

      await vm.axios.post(`${vm.$store.state.baseApiURL}/api/users/post`, {
        name: vm.newData.name,
        phone_number: vm.newData.phone_number,
        username: vm.newData.username,
        password: vm.newData.password,
        role: vm.newData.role,
        status: vm.newData.status,
        telegram_id: vm.newData.telegram_id
      }, 
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        if(Array.isArray(response.data)){
          vm.resetAddModal();
          vm.showSnackbar("Add User Success!", "");
          vm.getData();
        }
        else if(response.data.toLowerCase() == "username already exists"){
          vm.showSnackbar("Failed to Add New User.", "Username already used.");
        }
        else{
          vm.resetAddModal();
          vm.showSnackbar("Add User Success!", "");
          vm.getData();
        }
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.addUser(event);
          }
        }
        else{
          vm.showSnackbar("Failed to Add New User.", err.response.data);
          vm.addModalShow = false;
        }
      })

      //vm.addModalShow = false;
      vm.$store.state.isLoading = false;
    },

    async editUser(event) {
      const vm = this;

      event.preventDefault()
      vm.$store.state.isLoading = true;

      await vm.axios.put(`${vm.$store.state.baseApiURL}/api/users/update/${vm.pickedData.id}`, {
        name: vm.pickedData.name,
        phone_number: vm.pickedData.phone_number,
        username: vm.pickedData.username,
        password: vm.pickedData.password,
        role: vm.pickedData.role,
        status: vm.pickedData.status,
        telegram_id: vm.pickedData.telegram_id,
      }, 
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        if(response.status)
        vm.showSnackbar("Update User Success!", "");
        vm.editModalShow = false;
        vm.getData();
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.editUser(event);
          }
        }
        else{
          vm.showSnackbar("Failed to Update User.", err.response.data);
          vm.editModalShow = false;
        }
      })
      
      vm.$store.state.isLoading = false;
    },

    showSnackbar(title, message) {
      this.snackbar.message = `${title} ${message}`;
      this.snackbar.show = true;
    }
  },
  mounted(){
    const vm = this;

    if(vm.currentUser.role !== "direksi" && vm.currentUser.role !== "lead_marketing"){
      vm.$router.push({ path:'/login' });
    }
    else{
      if(vm.currentUser.role == "lead_marketing"){
        vm.roleFilter = "marketing";
      }
      vm.getData();
    }
  }
};
</script>

<style scoped>
</style>
