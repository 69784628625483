<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Event Data</h4>
            <p class="category">This table contains event data</p>
          </md-card-header>

          <md-card-content 
            v-if="dataIsLoading"
            class="text-center">
            <b-spinner style="width:5rem; height:5rem; font-size:25px" variant="success" label="Spinning"></b-spinner>
          </md-card-content>

          <md-card-content
            v-else>
            <b-row>
              <b-col sm="12"  md="3"></b-col>
              <b-col sm="12"  md="3"></b-col>
              <b-col sm="12" md="3" class="text-right">
                <md-button 
                  class="md-success w-100"
                  @click="addModalShow = true">
                  <md-icon>library_add</md-icon>
                  New Event
                </md-button>
              </b-col>
              <b-col sm="12" md="3" class="text-right">
                <md-button 
                  class="w-100"
                  :class="{ 'md-info': !dataFilterShow, 'md-danger': dataFilterShow }"
                  @click="dataFilterShow = !dataFilterShow">
                  <md-icon>{{ dataFilterShow == true ? 'arrow_circle_up' : 'arrow_circle_down' }}</md-icon>
                  {{ dataFilterShow == true ? 'Hide Data Filter' : 'Show Data Filter' }}
                </md-button>
              </b-col>
            </b-row>

            <b-collapse v-model="dataFilterShow">
              <b-row>
                <b-col>
                <md-field>
                  <label>Search</label>
                  <md-input v-model="searchText" placeholder="Search by name or place"></md-input>
                  <b-button
                    variant="secondary"
                    size="sm"
                    @click="getData()"
                    >
                    <md-icon class="text-white">search</md-icon>
                  </b-button>
                </md-field>
              </b-col>

              <b-col>
                <md-field>
                  <b-form-select 
                    class="w-100"
                    v-model="perPage" 
                    :options="perPageOptions"
                    style="border-bottom: none">
                  </b-form-select>
                </md-field>
              </b-col>

                <b-col sm="12" md="3">
                  <md-button 
                    class="md-primary w-100"
                    @click="resetFilter()">
                    <md-icon>refresh</md-icon>
                    Reset Filter
                  </md-button>
                </b-col>
              </b-row>
            </b-collapse>

            <b-table 
              style="max-height: 400px; overflow-y: auto"
              responsive 
              hover 
              :items="filteredItems" 
              :fields="fields">

              <template #cell(date_start)="data">
                {{ new Date(data.value).toLocaleDateString() }}
              </template>

              <template #cell(date_end)="data">
                {{ new Date(data.value).toLocaleDateString() }}
              </template>

              <template #cell(id)="data">
                <!--<b-button
                  size="sm"
                  variant="success"
                  class="ms-2 mt-1"
                  @click="detailEvent(data.value)"
                  >
                  <md-icon class="text-white">add</md-icon>
                  ADD NUMBER
                </b-button>-->
                <b-button
                  size="sm"
                  variant="primary"
                  class="ms-2 mt-1"
                  @click="detailEvent(data.value)"
                  >
                  <md-icon class="text-white">launch</md-icon>
                </b-button>

                <b-button
                  size="sm"
                  variant="warning"
                  class="ms-2 mt-1"
                  @click="pickData(data.item); editModalShow = true"
                  >
                  <md-icon>create</md-icon>
                </b-button>
                <!--
                <b-button
                  size="sm"
                  variant="danger"
                  class="ms-2 mt-1"
                  @click="deleteEvent(data.value)"
                  >
                  <md-icon class="text-white">delete</md-icon>
                </b-button>
                -->
              </template>

            </b-table>
          </md-card-content>

          <md-card-content>
            <b-row>
              <b-col>
                <b-button
                  :disabled="previousPageDisabled"
                  variant="primary"
                  size="sm"
                  @click="changePage('prev')"
                  >
                  <md-icon class="text-white">arrow_left</md-icon>
                </b-button>
                
                <b-button
                  disabled
                  variant="success"
                  size="sm"
                  class="mx-2 px-3"
                  >
                  <span style="font-size:1.05rem; font-weight:700">{{ currentPage }}</span>
                </b-button>

                <b-button
                  :disabled="nextPageDisabled"
                  variant="primary"
                  size="sm"
                  @click="changePage('next')"
                  >
                  <md-icon class="text-white">arrow_right</md-icon>
                </b-button>
              </b-col>
            </b-row>
          </md-card-content>
        </md-card>

        <b-modal
          ref="modal"
          v-model="addModalShow"
          :hide-header-close="true"
          header-variant="success"
          hide-footer
        >
          <template #modal-header>
            <b-row class="w-100 mx-auto">
              <b-col class="p-0">
                <b-alert show variant="success">
                  <b style="font-size: 1.2rem">Add New Event</b>
                </b-alert>
              </b-col>
            </b-row>
          </template>

          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group
            >
              <label for="name"><b>Name</b></label>
              <b-form-input
                id="name"
                v-model="newData.name"
                placeholder="Input the event name here..."
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              class="my-4"
            >
            <label for="place"><b>Place</b></label>
              <b-form-input
                id="place"
                v-model="newData.place"
                placeholder="Input the event place here..."
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              class="my-4"
            >
              <label for="date-start">Date Start</label>
              <b-form-input
                id="date-start"
                type="date"
                v-model="newData.dateStart"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              class="my-4"
            >
              <label for="date-end">Date End</label>
              <b-form-input
                id="date-end"
                type="date"
                v-model="newData.dateEnd"
                required
                class=""
              ></b-form-input>
            </b-form-group>

            <md-button
              type="submit"
              class="me-2 md-success"
            >
              Submit
            </md-button>

            <md-button
              class="md-danger"
              size="md"
              @click="resetAddModal()"
            >
              Cancel
            </md-button>
          </form>
        </b-modal>

        <b-modal
          v-if="pickedEventData"
          ref="modal"
          v-model="editModalShow"
          :hide-header-close="true"
          header-variant="success"
          hide-footer
        >
          <template #modal-header>
            <b-row class="w-100 mx-auto">
              <b-col class="p-0">
                <b-alert show variant="warning">
                  <b style="font-size: 1.2rem">Edit Event</b>
                </b-alert>
              </b-col>
            </b-row>
          </template>

          <form ref="form" @submit.stop.prevent="editEvent">
            <b-form-group
            >
              <label for="name"><b>Name</b></label>
              <b-form-input
                id="name"
                v-model="pickedEventData.name"
                placeholder="Input the event name here..."
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              class="my-4"
            >
            <label for="place"><b>Place</b></label>
              <b-form-input
                id="place"
                v-model="pickedEventData.place"
                placeholder="Input the event place here..."
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              class="my-4"
            >
              <label for="date-start">Date Start</label>
              <b-form-input
                id="date-start"
                type="date"
                v-model="pickedEventData.date_start_picker"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              class="my-4"
            >
              <label for="date-end">Date End</label>
              <b-form-input
                id="date-end"
                type="date"
                v-model="pickedEventData.date_end_picker"
                required
                class=""
              ></b-form-input>
            </b-form-group>

            <md-button
              type="submit"
              class="me-2 md-success"
            >
              Save
            </md-button>

            <md-button
              class="md-danger"
              size="md"
              @click="editModalShow = false"
            >
              Cancel
            </md-button>
          </form>
        </b-modal>
      </div>
    </div>

    <md-snackbar :md-position="'center'" :md-duration="5000" :md-active.sync="snackbar.show" md-persistent>
      <span>{{ snackbar.message }}</span>
      <md-button class="md-primary" @click="snackbar.show = false">Close</md-button>
    </md-snackbar>
  </div>
</template>

<script>

export default {
  data(){
    return{
      snackbar:{
        message: "",
        show: false,
      },

      dataFilterShow: true,
      dataIsLoading: false,
      addModalShow: false,
      editModalShow: false,
      deleteModalShow: false,
      newData:{
        name: "",
        place: "",
        dateStart: "",
        dateEnd: ""
      },
      pickedEventData: {},
      currentPage: 1,
      perPage: 25,
      perPageOptions:[
          { value: 25, text: '25 data each page' },
          { value: 50, text: '50 data each page' },
          { value: 100, text: '100 data each page' },
          { value: 200, text: '200 data each page' }
      ],
      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'place',
          sortable: true
        },
        {
          key: 'date_start',
          sortable: true
        },
        {
          key: 'date_end',
          sortable: true
        },
        { 
          key: 'totalCollectedPhoneNumber', 
          label: 'Total Number Collected' 
        },
        {
          key: 'id',
          label: 'Action'
        }
      ],
      searchText: "",
    }
  },
  components: {
  },
  computed:{
    events(){
      return this.$store.state.events;
    },
    collectedPhoneNumbers(){
      return this.$store.state.collectedPhoneNumbers;
    },
    totalRows(){
      let totalRow = Math.ceil(this.filteredItems.length/this.perPage)
      return totalRow;
    },
    filteredItems(){      
      let filteredItems = [];
      const vm = this;

      filteredItems = vm.events;

      return filteredItems;
    },
    nextPageDisabled(){
      const vm = this;

      if(vm.perPage <= vm.filteredItems.length){
        return false;
      }
      else{
        return true;
      }
    },
    previousPageDisabled(){
      const vm = this;

      if(vm.currentPage > 1){
        return false;
      }
      else{
        return true;
      }
    },
  },
  watch:{
    perPage(newValue, oldValue) {
      if(newValue != oldValue){
        const vm = this;
        
        vm.currentPage = 1;
        vm.getData();
      }
    }
  },
  methods:{
    changePage(condition){
      const vm = this;

      if(condition == 'next'){
        vm.currentPage++;
        vm.getData();
      }
      else{
        vm.currentPage--;
        vm.getData();
      }
    },

    resetFilter(){
      const vm = this;

      vm.searchText = "";
      vm.getData();
    },

    getData(){
      const vm = this;
      let url = `${vm.$store.state.baseApiURL}/api/events/get?page=${vm.currentPage}&size=${vm.perPage}`;

      if(vm.searchText != "" && vm.searchText){
        url = url + `&search=${vm.searchText}`;
      }

      vm.requestDataAPI(url);
    },

    async requestDataAPI(url){
      const vm = this;

      vm.dataIsLoading = true;

      await vm.axios.get(url, 
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        if(Array.isArray(response.data)){        
          vm.$store.state.events = response.data.sort((a,b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
        }
        else{
          vm.$store.state.events = [];
        }
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.requestDataAPI(url);
          }
        }
        else{
          vm.showSnackbar("Failed to Get Events Data", err.message);
        }
      })
      
      vm.dataIsLoading = false;
    },

    detailEvent(eventId){
      const vm = this;

      vm.$router.push(`/event/${eventId}`);
    },
    resetAddModal(){
      const vm = this;

      vm.newData.name = "";
      vm.newData.place = "";
      vm.newData.dateStart = "";
      vm.newData.dateEnd = "";

      vm.addModalShow = false;
    },

    async handleSubmit(event) {
      const vm = this;

      event.preventDefault()
      vm.$store.state.isLoading = true;

      await vm.axios.post(`${vm.$store.state.baseApiURL}/api/events/post`, {
        name: vm.newData.name,
        place: vm.newData.place,
        date_start: new Date(vm.newData.dateStart),
        date_end: new Date(vm.newData.dateEnd)
      }, 
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.resetAddModal();
        vm.getData();
        vm.showSnackbar("Add New Event Success!", `Detail: ${response.data}`);
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.requestDataAPI(url);
          }
        }
        else{
          vm.addModalShow = false;
          vm.showSnackbar("Failed to Add New Event", err.message);
        }
      })

      vm.$store.state.isLoading = false;
    },

    async editEvent(event) {
      const vm = this;

      event.preventDefault()
      vm.$store.state.isLoading = true;

      await vm.axios.put(`${vm.$store.state.baseApiURL}/api/events/update/${vm.pickedEventData.id}`, {
        name: vm.pickedEventData.name,
        place: vm.pickedEventData.place,
        date_start: new Date(vm.pickedEventData.date_start_picker),
        date_end: new Date(vm.pickedEventData.date_end_picker)
      }, 
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.editModalShow = false;
        vm.getData();
        vm.showSnackbar("Update Event Success!", `Detail: ${response.data}`);
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.requestDataAPI(url);
          }
        }
        else{
          vm.editModalShow = false;
          vm.showSnackbar("Failed to Update the Event Event", err.response.data);
        }
      })

      vm.$store.state.isLoading = false;
    },

    pickData(data){
      const vm = this;

      data.date_start_picker = new Date(data.date_start).toLocaleDateString('en-CA');
      data.date_end_picker = new Date(data.date_end).toLocaleDateString('en-CA');
      vm.pickedEventData = JSON.parse(JSON.stringify(data));
    },
    deleteEvent(eventId){
      const vm = this;

      vm.pickedEventData = vm.filteredItems.find(item => item.id == eventId );
      vm.deleteModalShow = true;
    },

    showSnackbar(title, message) {
      this.snackbar.message = `${title}. Detail: ${message}`;
      this.snackbar.show = true;
    },
  },
  mounted(){
    const vm = this;
    let currentUserRole = vm.$store.state.user.role;

    if(currentUserRole !== "lead_marketing" && currentUserRole !== "direksi"){
      vm.$router.push({ path:'/login' })
    }
    else{
      vm.getData();
    }
  }
};
</script>

<style scoped>
</style>
