<template>
  <md-card class="md-card-profile">
    <div class="md-card-avatar">
      <img class="img" :src="`https://avatars.dicebear.com/api/initials/${userData.name}.svg`" />
    </div>

    <md-card-content>
      <h6 class="category text-gray">{{ userData.role ? userData.role.toUpperCase() : "" }}</h6>
      <h4 class="card-title">{{ userData.name ? userData.name.toUpperCase() : "" }}</h4>
      <md-button
        @click="copyToClipBoard(userData.phone_number)" 
        class="md-round md-success d-block w-100">
        {{ userData.phone_number }} 
        <md-icon class="ms-2">
          content_copy
        </md-icon>
      </md-button>
      <md-button
        class="md-round md-primary d-block d-md-none"
        :href="`tel:${userData.phone_number}`">
        Save Number (Mobile Only)
        <md-icon class="ms-2">
          save
        </md-icon>
      </md-button>
    </md-card-content>
  </md-card>
</template>
<script>
export default {
  name: "user-card",
  props: {
    cardUserImage: {
      type: String,
      default: require("@/assets/img/faces/marc.jpg"),
    },
    userData: {},
  },
  data() {
    return {
    };
  },
  computed:{
  },
  methods:{
    copyToClipBoard(number){
      navigator.clipboard.writeText(number);
    }
  },
  mounted(){
    const vm = this;
  }
};
</script>
<style></style>
