<template>
  <div class="content">
    <div class="md-layout">
    
      <div class="md-layout-item md-medium-size-100">
        <user-card :userData="userData"> </user-card>
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Profile Detail ( ID: {{ tempData.id }} )</h4>
          </md-card-header>

          <md-card-content>
            <form @submit.prevent="editCustomer">
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Name</label>
                    <md-input v-model="tempData.name" :disabled="!isEdit" required></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Phone Number</label>
                    <md-input v-model="tempData.phone_number" :disabled="!isEdit" required></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Username</label>
                    <md-input v-model="tempData.username" :disabled="!isEdit" required></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Password</label>
                    <md-input v-model="tempData.password" :disabled="!isEdit" required></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Telegram ID</label>
                    <md-input v-model="tempData.telegram_id" :disabled="!isEdit" required></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Status</label>
                    <md-input v-if="!isEdit" v-model="tempData.statusFormatted" :disabled="!isEdit" required></md-input>
                    <md-select v-else v-model="tempData.status" required>
                      <md-option value="1">Active</md-option>
                      <md-option value="0">Not Active</md-option>
                    </md-select>
                  </md-field>
                </div>

                <div v-if="!isEdit" class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Created At</label>
                    <md-input 
                      v-model="tempData.createdAtFormatted" 
                      disabled>
                    </md-input>
                  </md-field>
                </div>

                <div v-if="!isEdit" class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Last Updated</label>
                    <md-input v-model="tempData.updatedAtFormatted" disabled></md-input>
                  </md-field>
                </div>
              </div>

              <!--
              <md-button
                v-if="currentUser.role == 'admin' || currentUser.role == 'direksi' && !isEdit"
                @click="isEdit = true"
                class="me-2 md-warning"
              >
                Edit
              </md-button>

              <md-button
                v-if="isEdit"
                type="submit"
                class="me-2 md-success"
                @click="editCustomer()"
              >
                Save
              </md-button>

              <md-button
                v-if="isEdit"
                class="md-danger"
                size="md"
                @click="resetUserData()"
              >
                Cancel
              </md-button>-->
            </form>
          </md-card-content>
        </md-card>
      </div>
    </div>

    <md-snackbar :md-position="'center'" :md-duration="5000" :md-active.sync="snackbar.show" md-persistent>
      <span>{{ snackbar.message }}</span>
      <md-button class="md-primary" @click="snackbar.show = false">Close</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import { UserCard } from "@/pages";

export default {
  components: {
    UserCard,
  },
  data(){
    return{
      snackbar:{
        message: "",
        show: false,
      },
      userData: {},
      tempData: {},
      isEdit: false,

      statusOptions:[
          { value: 1, text: 'Active' },
          { value: 0, text: 'Not Active' },
      ],
    }
  },
  watch:{
    userData(){
      const vm = this;
      vm.userData.createdAtFormatted = new Date(vm.userData.created_at).toLocaleDateString()
      vm.userData.updatedAtFormatted = new Date(vm.userData.updated_at).toLocaleDateString()
      vm.userData.statusFormatted = vm.userData.status == 1 ? "Active" : "Not Active";
      vm.tempData = JSON.parse(JSON.stringify(vm.userData));
    },
  },
  computed:{
    currentUser(){
      return this.$store.state.user;
    },
    currentUserDetailId(){
      return this.$route.params.id;
    },
    customers(){
      return this.$store.state.customers;
    },
  },
  methods:{
    async requestDataAPI(){
      const vm = this;
      vm.dataIsLoading = true;
      let url = `${vm.$store.state.baseApiURL}/api/users/get/${vm.currentUser.id}`;
      await vm.axios.get(url,
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.userData = response.data[0];
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.requestDataAPI();
          }
        }
        else{
          vm.showSnackbar(`Failed to Get User Data with ID: ${vm.currentUser}`,'');
        }
      })
      
      vm.dataIsLoading = false;
    },

    async editCustomer () {
      const vm = this;
      
      vm.$store.state.isLoading = true;

      await vm.axios.put(`${vm.$store.state.baseApiURL}/api/customers/${vm.currentUserDetailId}`, {
        name: vm.tempData.name,
        phone_number: vm.tempData.phone_number,
      },
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.isEdit = false;
        vm.requestDataAPI();
        vm.showSnackbar("Update Customer Success!", "");
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.editCustomer();
          }
        }
        else{
          vm.showSnackbar("Failed to Update the Customer", err.message);
        }
      })

      vm.$store.state.isLoading = false;
    },

    resetUserData(){
      const vm = this;
      vm.tempData = JSON.parse(JSON.stringify(vm.userData));
      vm.isEdit = false;
    },
    showSnackbar(title, message) {
      this.snackbar.message = `${title}`;
      this.snackbar.show = true;
    },
  },
  async mounted(){
    const vm = this;
    vm.requestDataAPI();
  }
};
</script>
