<template>
  <md-toolbar md-elevation="0" class="md-transparent">
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ $route.name }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <md-list>

            <md-list-item href="#/profile">
              <i class="material-icons" style="font-size:2.5rem">account_circle</i>
              <p class="hidden-lg hidden-md">Profile</p>
              {{ currentUser.name }}
            </md-list-item>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
export default {
  data() {
    return {
      selectedEmployee: null,
      sidebarIsShowed: false,
      employees: [
        "Jim Halpert",
        "Dwight Schrute",
        "Michael Scott",
        "Pam Beesly",
        "Angela Martin",
        "Kelly Kapoor",
        "Ryan Howard",
        "Kevin Malone",
      ],
    };
  },
  methods: {
    toggleSidebar() {
      this.$store.state.sidebarIsShowed = !this.$store.state.sidebarIsShowed;
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
  },
  computed:{
    currentUser(){
      return this.$store.state.user;
    }
  }
};
</script>

<style lang="css">
</style>
