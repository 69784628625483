<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Step Data</h4>
            <p class="category">This table is containing step data that can be used in customer progress</p>
          </md-card-header>

          <md-card-content 
            v-if="dataIsLoading"
            class="text-center">
            <b-spinner style="width:5rem; height:5rem; font-size:25px" variant="success" label="Spinning"></b-spinner>
          </md-card-content>

          <md-card-content
            v-else>
            <b-row>
             <!--<b-col>
                <md-field>
                  <label>Search</label>
                  <md-input v-model="searchText" placeholder="Search by name"></md-input>
                  <b-button
                    variant="secondary"
                    size="sm"
                    @click="getData()"
                    >
                    <md-icon class="text-white">search</md-icon>
                  </b-button>
                </md-field>
              </b-col>-->

              <b-col class="text-right">
                <md-button 
                  class="md-success"
                  @click="addModalShow = true">
                  <md-icon>library_add</md-icon>
                  New Step
                </md-button>
              </b-col>
            </b-row>
            <b-table
              style="max-height: 300px; overflow-y: auto"
              responsive
              hover 
              :items="filteredItems" 
              :fields="fields">
              <template #cell(name)="data">
                <b style="font-weight:500">{{ data.value.toUpperCase() }}</b>
              </template>

              <template #cell(created_at)="data">
                {{ new Date(data.value).toLocaleDateString() }} | {{ new Date(data.value).toLocaleTimeString() }}
              </template>

              <template #cell(updated_at)="data">
                {{ new Date(data.value).toLocaleDateString() }} | {{ new Date(data.value).toLocaleTimeString() }}
              </template>

              <template #cell(id)="data">
                <!--<b-button
                  variant="primary"
                  class="ms-2 mt-1"
                  size="sm"
                  @click="detailCustomer(data.value)"
                  >
                  <md-icon class="text-white">launch</md-icon>
                </b-button>-->
                <b-button
                  v-if="data.value > 4"
                  variant="warning"
                  size="sm"
                  class="ms-2 mt-1"
                  @click="pickData(data.item); editModalShow = true;"
                  >
                  <md-icon>edit</md-icon>
                </b-button>
                <b-button
                  v-if="false"
                  variant="danger"
                  size="sm"
                  class="ms-2 mt-1"
                  @click="pickData(data.item); deleteModalShow = true;"
                  >
                  <md-icon class="text-white">delete</md-icon>
                </b-button>
              </template>

            </b-table>
          </md-card-content>
        </md-card>

        <b-modal
          ref="modal"
          v-model="addModalShow"
          :hide-header-close="true"
          header-variant="success"
          hide-footer
        >
          <template #modal-header>
            <b-row class="w-100 mx-auto">
              <b-col class="p-0">
                <b-alert show variant="success">
                  <b style="font-size: 1.2rem">Add New Step</b>
                </b-alert>
              </b-col>
            </b-row>
          </template>

          <form ref="form" @submit.stop.prevent="addStep">
            <b-form-group
              class="mb-4"
            >
              <label for="name-input"><b>Step Name (All Lowercase)</b></label>
              <b-form-input
                id="name-input"
                v-model="newData.name"
                placeholder="Example: site visit"
                required
              ></b-form-input>
            </b-form-group>
            <hr>
            <b-button
              variant="success"
              size="md"
              type="submit"
              class="me-2"
            >
              Submit
            </b-button>

            <b-button
              variant="danger"
              size="md"
              @click="resetAddModal()"
            >
              Cancel
            </b-button>
          </form>
        </b-modal>

        <b-modal
          ref="modal"
          v-model="editModalShow"
          :hide-header-close="true"
          header-variant="warning"
          hide-footer
        >
          <template #modal-header>
            <b-row class="w-100 mx-auto" no-gutters>
              <b-col class="p-0">
                <b-alert show variant="warning">
                  <b style="font-size: 1.2rem">Edit Step</b>
                </b-alert>
              </b-col>
            </b-row>
          </template>

          <form ref="form" @submit.stop.prevent="editStep">
            <b-form-group
              class="mb-3"
            >
              <label for="name-input"><b>Step Name (All Lowercase)</b></label>
              <b-form-input
                id="name-input"
                v-model="pickedData.name"
                placeholder="Example: site visit"
                required
              ></b-form-input>
            </b-form-group>
            <p class="text-danger mb-3">
              WARNING! The update will be apllied to all customer progresses that have been using this step.
            </p>
            <hr>
            <b-button
              variant="success"
              size="md"
              type="submit"
              class="me-2"
            >
              Save
            </b-button>

            <b-button
              variant="danger"
              size="md"
              @click="editModalShow = false"
            >
              Cancel
            </b-button>
          </form>
        </b-modal>
      </div>
    </div>

    <md-snackbar :md-position="'center'" :md-duration="5000" :md-active.sync="snackbar.show" md-persistent>
      <span>{{ snackbar.message }}</span>
      <md-button class="md-primary" @click="snackbar.show = false">Close</md-button>
    </md-snackbar>
  </div>
</template>

<script>

export default {
  data(){
    return{
      snackbar:{
        message: "",
        show: false,
      },
      dataIsLoading: false,
      addModalShow: false,
      editModalShow: false,
      deleteModalShow: false,
      pickedData: {},
      newData:{
        name: "",
        phoneNumber: "",
      },
      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'created_at',
          sortable: true
        },
        {
          key: 'updated_at',
          sortable: true
        },
        {
          key: 'id',
          label: 'Action'
        }
      ],
      items: [],
      searchText: "",
    }
  },
  components: {
  },
  computed:{
    currentUser(){
      return this.$store.state.user;
    },
    steps(){
      return this.$store.state.steps;
    },
    filteredItems(){      
      let filteredItems = [];
      const vm = this;

      if(vm.searchText == ""){
        filteredItems = vm.steps;
      }
      else{
        let formattedSearchText = vm.searchText.toLowerCase();

        filteredItems = vm.steps.filter(item => {
          if(item.name.toLowerCase().includes(formattedSearchText) == true){
            return true;
          }
          else{
            return false;
          }
        });
      }

      return filteredItems;
    }
  },
  watch:{
  },
  methods:{
    getData(){
      const vm = this;
      let url = `${vm.$store.state.baseApiURL}/api/steps/get`;

      vm.requestDataAPI(url);
    },

    async requestDataAPI(url){
      const vm = this;

      vm.dataIsLoading = true;

      await vm.axios.get(url, 
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        if(Array.isArray(response.data)){        
          vm.$store.state.steps = response.data;
        }
        else{
          vm.$store.state.steps = [];
        }
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.requestDataAPI(url);
          }
        }
        else{
          vm.showSnackbar("Failed to Get Steps Data", err.message);
        }
      })
      
      vm.dataIsLoading = false;
    },

    pickData(data){
      const vm = this;

      vm.pickedData = JSON.parse(JSON.stringify(data));
    },

    detailCustomer(customerId){
      const vm = this;

      vm.$router.push(`/customer/${customerId}`);
    },
    resetAddModal(){
      const vm = this;

      vm.newData.name = "";
      vm.newData.phoneNumber = "";
      vm.addModalShow = false;
    },
    async addStep(event) {
      const vm = this;

      event.preventDefault()
      vm.$store.state.isLoading = true;

      await vm.axios.post(`${vm.$store.state.baseApiURL}/api/steps/post`, {
        name: vm.newData.name.toLowerCase()
      }, 
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        if(response.status)
        vm.resetAddModal();
        vm.showSnackbar("Add Step Success!", "");
        vm.addModalShow = false;
        vm.getData();
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.addStep(event);
          }
        }
        else{
          vm.showSnackbar("Failed to Add New Step.", err.response.data);
          vm.addModalShow = false;
        }
      })

      vm.$store.state.isLoading = false;
    },

    async editStep(event) {
      const vm = this;

      event.preventDefault()
      vm.$store.state.isLoading = true;

      await vm.axios.put(`${vm.$store.state.baseApiURL}/api/steps/update/${vm.pickedData.id}`, {
        name: vm.pickedData.name
      }, 
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        if(response.status)
        vm.showSnackbar("Update Step Success!", "");
        vm.editModalShow = false;
        vm.getData();
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.editStep(event);
          }
        }
        else{
          vm.showSnackbar("Failed to Edit Step.", err.response.data);
          vm.editModalShow = false;
        }
      })

      vm.$store.state.isLoading = false;
    },

    async deleteStep() {
      const vm = this;

      await vm.axios.delete(`${vm.$store.state.baseApiURL}/api/steps/delete/${vm.pickedData.id}`, 
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        if(response.status)
        vm.showSnackbar("Add Step Success!", "");
        vm.addModalShow = false;
        vm.getData();
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.deleteStep();
          }
        }
        else{
          vm.showSnackbar("Failed to Delete Step.", err.response.data);
          vm.deleteModalShow = false;
        }
      })
    },

    showSnackbar(title, message) {
      this.snackbar.message = `${title} ${message}`;
      this.snackbar.show = true;
    },
  },
  mounted(){
    const vm = this;

    if(vm.currentUser.role != "direksi"){
      vm.$router.push({ path: '/login' });
    }
    else{
      vm.getData();
    }

    //vm.filteredItems = vm.items;
  }
};
</script>

<style scoped>
</style>
