<template>
  <md-card>
    <md-card-header data-background-color="purple">
      <h4 class="title">Transaction History</h4>
    </md-card-header>

    <md-card-content style="max-height: 250px; overflow-y:auto">
      <b-alert 
        v-for="(transaction, i) in finishedTransaction" 
        :key="i"
        show 
        :variant="'secondary'">
        <b-row>
          <b-col cols="12">
            <b style="font-size:1rem">
              {{ new Date(transaction.created_at).toLocaleDateString() }}
            </b>
          </b-col>
        </b-row>
      </b-alert>
    </md-card-content>
  </md-card>
</template>
<script>
export default {
  name: "transaction-history",
  props: {
    transactionData: [],
    relatedCustomer: {},
    data: [],
  },
  data() {
    return {
      dataTransaction:[],
    };
  },
  computed:{
    finishedTransaction(){
      const vm = this;

      if(vm.data){
        let dataCopy = JSON.parse(JSON.stringify(vm.data))
        return dataCopy.sort((a,b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
      }
      else{
        return [];
      }
    },
  },
  mounted(){
    const vm = this;
  }
};
</script>
<style></style>
