<template>
  <md-card>
    <md-card-header data-background-color="purple">
      <h3 class="title">Collected Phone Numbers</h3>
    </md-card-header>

    <md-card-content>
      <div class="md-layout">
        <b-row class="w-100 m-0">
          <b-col cols="12" sm="6" md="4">
            <md-button
              class="text-left md-success w-100"
              @click="formWarningMessage = ''; addCollapseShow = !addCollapseShow">
              <span style="font-size:14px">
                Add Phone Number
                <b-icon icon="plus-circle" />
              </span>
            </md-button>
          </b-col>
          <b-col cols="12" sm="6" md="5">
            <md-button 
              class="text-left md-info w-100"
              @click="phoneNumberCollapseShow = !phoneNumberCollapseShow">
              <span style="font-size:14px">
                Show Collected Numbers
                <md-icon>arrow_circle_down</md-icon>
              </span>
            </md-button>
          </b-col>
        </b-row>

        <b-row class="w-100 p-0 m-0" :no-gutters="true">
          <b-col class="p-0" cols="12">
            <b-collapse v-model="addCollapseShow" accordion="phone-number-collapse">
              <b-card>
                <h4 style="font-weight:700">Add Phone Number</h4>
                <form ref="form" @submit.stop.prevent="checkPhoneNumber">
                  <b-form-group
                    class="my-2"
                  >
                      <label><b>Phone Number (13 Digit Max)</b></label>
                      <md-field>
                        <md-input 
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          type = "number"
                          maxlength = "13"
                          v-model="newPhoneNumberData.phone_number" 
                          placeholder="Input phone number here..."
                          required>
                        </md-input>
                      </md-field>
                  </b-form-group>

                  <b-form-group
                    class="my-2"
                  >
                      <label><b>Name</b></label>
                      <md-field>
                        <md-input 
                          v-model="newPhoneNumberData.name" 
                          placeholder="Input name here..."
                          required>
                        </md-input>
                      </md-field>
                  </b-form-group>

                  <b-form-group
                    class="my-2 mx-0"
                  >
                    <label><b>Pick the Related Marketing</b></label>
                    <b-form-input 
                      class="w-100"
                      placeholder="Filter marketing by name..."
                      v-model="searchMarketingText">
                    </b-form-input>

                    <b-form-select 
                      class="w-100"
                      placeholder="Pick here..."
                      v-model="newPhoneNumberData.user_id" 
                      :options="tempListMarketing"
                      required>
                    </b-form-select>
                  </b-form-group>

                  <b class="text-danger" v-if="formWarningMessage">{{ formWarningMessage }}</b><br>
                  <b-card v-if="registeredPhoneNumberData">
                    <span v-for="(data, name, i) in registeredPhoneNumberData" :key="i">
                      <b>
                        {{ name }}:
                      </b>
                      {{ name == "created_at" || name == "updated_at" ? new Date(data).toLocaleDateString() : data }}
                      <br>
                    </span>
                  </b-card>

                  <div 
                    v-if="registeredPhoneNumberData"
                    class="my-1">
                    <b>Is it the same person?</b>
                    <ul>
                      <li>
                        If you click on "Yes", the system won't add a new customer based on the inputed data.
                      </li>
                      <li>
                        If you click on "No", the system will add a new customer based on the inputed data.
                      </li>
                    </ul>
                  </div>

                  <md-button
                    v-if="registeredPhoneNumberData"
                    class="me-2 md-success"
                    @click="addPhoneNumber(true)"
                  >
                    Yes
                  </md-button>

                  <md-button
                    v-if="registeredPhoneNumberData"
                    class="me-2 md-warning"
                    @click="addPhoneNumber(false)"
                  >
                    No
                  </md-button>

                  <md-button
                    v-else
                    type="submit"
                    class="me-2 md-success"
                  >
                    Submit
                  </md-button>

                  <md-button
                    class="md-danger"
                    size="md"
                    @click="resetAdd()"
                  >
                    Cancel
                  </md-button>
                </form>
              </b-card>
            </b-collapse>

            <b-collapse v-model="phoneNumberCollapseShow" accordion="phone-number-collapse">
              <b-row class="px-2">
                <b-col sm="12" md="4">
                  <md-field>
                    <label>Search</label>
                    <md-input v-model="searchName" placeholder="Search by name"></md-input>
                    <b-button
                      variant="secondary"
                      size="sm"
                      @click="getData()"
                      >
                      <md-icon class="text-white">search</md-icon>
                    </b-button>
                  </md-field>
                </b-col>

                <b-col sm="12" md="4">
                  <md-field>
                    <b-form-select 
                      class="w-100"
                      v-model="marketingFilter" 
                      :options="marketingFilterOptions"
                      @change="getData()"
                      style="border-bottom: none">
                    </b-form-select>
                  </md-field>
                </b-col>

                <!--<b-col sm="12" md="3">
                  <md-field>
                    <b-form-select 
                      class="w-100"
                      v-model="perPage" 
                      :options="perPageOptions"
                      style="border-bottom: none">
                    </b-form-select>
                  </md-field>
                </b-col>-->

                <b-col sm="12" md="4">
                  <md-button 
                    class="md-primary w-100"
                    @click="resetFilter()">
                    <md-icon>refresh</md-icon>
                    Reset Filter
                  </md-button>
                </b-col>
              </b-row>

              <b-card style="max-height:500px; overflow-y: auto" v-if="collectedPhoneNumbers.length > 0">
                <b-alert 
                  v-for="(number, i) in collectedPhoneNumbers" 
                  :key="i"
                  show
                  :style="{ 'background-color': number.status == 0 ? 'rgba(40, 167, 69, 0.8)': 'rgba(220, 53, 69, 0.8)' }">
                  <b-row>
                    <b-col cols="12" sm="6">
                      <b style="font-weight:500; font-size: 1rem">
                        {{ number.phone_number }}
                      </b>
                      <br> 
                      <b style="font-weight:500; font-size: 0.8rem">
                        {{ number.name }} <br> Handled By : {{ number.marketing_name }}
                      </b>
                    </b-col>
                    <b-col cols="12" sm="6" class="mt-2">

                      <b-button
                        v-if="number.status == 0 && number.assigned == 0"
                        size="sm"
                        variant="primary"
                        style="float: right; border: solid 1px rgba(255,255,255,0.8); width: 150px" 
                        @click="assignPhoneNumber(number)"
                      >
                        ADD AS CUSTOMER
                      </b-button>

                      <b-button
                        v-else-if="number.status == 1 && number.assigned == 0"
                        size="sm"
                        variant="success"
                        style="float: right; border: solid 1px rgba(255,255,255,0.8); width: 150px" 
                        @click="assignPhoneNumber(number)"
                      >
                        NEW TRANSACTION
                      </b-button>

                      <b-button
                        v-else-if="number.assigned == 1"
                        size="sm"
                        variant="light"
                        disabled
                        style="float: right; border: solid 1px rgba(255,255,255,0.8); width: 150px" 
                      >
                        ASSIGNED
                      </b-button>

                      <!--<b-button
                        v-if="number.assigned == 0"
                        class="me-2"
                        size="sm"
                        variant="danger"
                        style="float: right; border: solid 1px rgba(255,255,255,0.8)"
                        @click="deletePhoneNumber(number)"
                      >
                        <b-icon icon="trash-fill"></b-icon>
                      </b-button>-->
                      <b-button
                        v-if="number.assigned == 0"
                        class="me-2"
                        size="sm"
                        variant="warning"
                        style="float: right; border: solid 1px rgba(255,255,255,0.8)" 
                        @click="editPhoneNumber(number)"
                      >
                        <b-icon icon="pencil-square"></b-icon>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-alert>
              </b-card>
              <h3 class="text-center" v-else>
                No Data Exist
              </h3>

              <!--<b-row class="mt-3">
                <b-col>
                  <b-button
                    :disabled="previousPageDisabled"
                    variant="primary"
                    size="sm"
                    @click="changePage('prev')"
                    >
                    <md-icon class="text-white">arrow_left</md-icon>
                  </b-button>
                  
                  <b-button
                    disabled
                    variant="success"
                    size="sm"
                    class="mx-2 px-3"
                    >
                    <span style="font-size:1.05rem; font-weight:700">{{ currentPage }}</span>
                  </b-button>

                  <b-button
                    :disabled="nextPageDisabled"
                    variant="primary"
                    size="sm"
                    @click="changePage('next')"
                    >
                    <md-icon class="text-white">arrow_right</md-icon>
                  </b-button>
                </b-col>
              </b-row>-->
            </b-collapse>
          </b-col>
        </b-row>
      </div>
    </md-card-content>

    <b-modal
      v-model="deletePhoneNumberModalShow"
      :hide-header-close="true"
      header-variant="danger"
      hide-footer
    >
      <template #modal-header>
        <b-row class="w-100 mx-auto">
          <b-col class="p-0">
            <b-alert show variant="danger">
              <b style="font-size: 1.2rem">Delete Phone Number</b>
            </b-alert>
          </b-col>
        </b-row>
      </template>

      <h5>
        This progress will be deleted. Are you sure?
      </h5>
      <b-row>
        <b-col>
          <b-button
            class="me-2"
            size="sm"
            variant="danger"
            @click="deletePhoneNumberModalShow = false;"
          >
            Delete
          </b-button>
          <b-button
            size="sm"
            variant="success"
            @click="deletePhoneNumberModalShow = false;"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      v-if="pickedPhoneNumber"
      v-model="editPhoneNumberModalShow"
      :hide-header-close="true"
      header-variant="danger"
      hide-footer
    >
      <template #modal-header>
        <b-row class="w-100 mx-auto">
          <b-col class="p-0">
            <b-alert show variant="warning">
              <b style="font-size: 1.2rem">Edit Phone Number</b>
            </b-alert>
          </b-col>
        </b-row>
      </template>

      <b-card>
        <form ref="form" @submit.stop.prevent="updatePhoneNumber">
          <b-form-group
              class="my-2"
            >
              <label><b>Phone Number (13 Digit Max)</b></label>
              <md-field>
                <md-input 
                  v-model="pickedPhoneNumber.phone_number" 
                  required
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  type = "number"
                  maxlength = "13"></md-input>
              </md-field>
          </b-form-group>

          <b-form-group
            class="my-2"
          >
              <label><b>Name {{ pickedPhoneNumber.customer_id ? '(Registered as Customer)' : '' }}</b></label>
              <md-field>
                <md-input 
                  :disabled="pickedPhoneNumber.customer_id ? true : false"
                  v-model="pickedPhoneNumber.name" 
                  required></md-input>
              </md-field>
          </b-form-group>

          <b-form-group
            class="my-2 mx-0"
          >
            <label><b>Related Marketing</b></label>
            <b-form-input 
              class="w-100"
              placeholder="Filter marketing by name..."
              v-model="searchMarketingText">
            </b-form-input>

            <b-form-select 
              class="w-100"
              v-model="pickedPhoneNumber.user_id" 
              :options="tempListMarketing"
              required>
            </b-form-select>
          </b-form-group>

          <md-button
            type="submit"
            class="me-2 md-success"
          >
            Save
          </md-button>

          <md-button
            class="md-danger"
            size="md"
            @click="editPhoneNumberModalShow = false"
          >
            Cancel
          </md-button>
        </form>
      </b-card>
    </b-modal>

    <b-modal
      v-if="pickedPhoneNumber"
      v-model="assignPhoneNumberModalShow"
      :hide-header-close="true"
      hide-footer
    >
      <template #modal-header>
        <b-row class="w-100 mx-auto">
          <b-col class="p-0">
            <b-alert show variant="success">
              <b style="font-size: 1.2rem">{{ pickedPhoneNumber.status == 0 ? 'Add as Customer' : 'New Transaction' }}</b>
            </b-alert>
          </b-col>
        </b-row>
      </template>

      <b-card>
        <p>
          {{  
            pickedPhoneNumber.status == 0 ?
            "This phone number will be added as a customer. Are you sure?"
            : "This phone number is already registered as a customer, so a new transaction will be made. Are you sure?"
          }}
        </p>

        <b-card v-if="registeredPhoneNumberData && pickedPhoneNumber.status == 0">
          <span class="text-danger">Phone number is already registered as a customer. Details below</span><br>
          <span v-for="(data, name, i) in registeredPhoneNumberData" :key="i">
            <b>
              {{ name }}:
            </b>
            {{ name == "created_at" || name == "updated_at" ? new Date(data).toLocaleDateString() : data }}
            <br>
          </span>
        </b-card>

        <div 
          v-if="registeredPhoneNumberData && pickedPhoneNumber.status == 0"
          class="my-1">
          <b>Is it the same person?</b>
          <ul>
            <li>
              If you click on "Yes", the system won't add a new customer based on the inputed data.
            </li>
            <li>
              If you click on "No", the system will add a new customer based on the inputed data.
            </li>
          </ul>
        </div>

        <md-button
          v-if="registeredPhoneNumberData && pickedPhoneNumber.status == 0"
          class="me-2 md-success"
          @click="assign(2)"
        >
          Yes
        </md-button>

        <md-button
          v-if="registeredPhoneNumberData && pickedPhoneNumber.status == 0"
          class="me-2 md-warning"
          @click="assign(0)"
        >
          No
        </md-button>

        <md-button
          v-else
          class="me-2 md-success"
          @click="assign(pickedPhoneNumber.status)"
        >
          Continue
        </md-button>

        <md-button
          class="md-danger"
          size="md"
          @click="assignPhoneNumberModalShow = false"
        >
          Cancel
        </md-button>
      </b-card>
    </b-modal>

    <md-snackbar :md-position="'center'" :md-duration="5000" :md-active.sync="snackbar.show" md-persistent>
      <span>{{ snackbar.message }}</span>
      <md-button class="md-primary" @click="snackbar.show = false">Close</md-button>
    </md-snackbar>
  </md-card>
</template>
<script>
export default {
  name: "list-collected-number",
  props: {
    data: [],
    eventData: {},
  },
  data() {
    return {
      snackbar:{
        message: "",
        show: false,
      },
      listMarketing: [],
      phoneNumberCollapseShow: true,
      addCollapseShow: false,
      editPhoneNumberModalShow: false,
      deletePhoneNumberModalShow: false,
      assignPhoneNumberModalShow: false,
      pickedPhoneNumber: {},
      newPhoneNumberData:{
        phone_number: "",
        name: "",
        user_id: null,
      },
      searchMarketingText: "",
      tempListMarketing: [],
      registeredPhoneNumberData: null,
      formWarningMessage: "",
      listCollectedPhoneNumbers: [],
      currentPage: 1,
      perPage: 5,
      perPageOptions:[
          { value: 5, text: '5 data each page' },
          { value: 10, text: '10 data each page' },
          { value: 25, text: '25 data each page' },
          { value: 50, text: '50 data each page' }
      ],

      searchName: "",
      marketingFilter: "all",
      marketingFilterOptions: [],
    };
  },
  watch:{
    searchMarketingText(){
      const vm = this;
      if(vm.searchMarketingText){
        vm.tempListMarketing = vm.tempListMarketing.filter(item => {
          return item.text.toLowerCase().includes(vm.searchMarketingText.toLowerCase())
        });
      }
      else{
        vm.tempListMarketing = JSON.parse(JSON.stringify(vm.listMarketing));
      }
    },
    listMarketing(){
      const vm = this;
      vm.tempListMarketing = JSON.parse(JSON.stringify(vm.listMarketing));
    },
    eventData(){
      const vm = this;
      vm.getData();
    }
  },
  computed:{
    currentEventId(){
      return this.$route.params.id;
    },
    events(){
      return this.$store.state.events;
    },
    collectedPhoneNumbers(){
      const vm = this;
      return vm.listCollectedPhoneNumbers;
    },
    nextPageDisabled(){
      const vm = this;

      if(vm.perPage <= vm.listCollectedPhoneNumbers.length){
        return false;
      }
      else{
        return true;
      }
    },
    previousPageDisabled(){
      const vm = this;

      if(vm.currentPage > 1){
        return false;
      }
      else{
        return true;
      }
    },
    involvedMarketingIds(){
      const vm = this;
      let ids = [];

      vm.marketingFilterOptions.forEach(data => {
        ids.push(data.value);
      })

      return ids;
    }
  },
  methods:{
    changePage(condition){
      const vm = this;

      if(condition == 'next'){
        vm.currentPage++;
        vm.getData();
      }
      else{
        vm.currentPage--;
        vm.getData();
      }
    },

    async getData() {
      const vm = this
      //let url = `${vm.$store.state.baseApiURL}/api/events/phone-numbers/${vm.eventData.id}?page=${vm.currentPage}&size=${vm.perPage}`;
      let url = `${vm.$store.state.baseApiURL}/api/events/phone-numbers/get/${vm.eventData.id}`;

      let count = 0;
      if(vm.searchName != "" && vm.searchName){
        url = count == 0 ? url + `?name=${vm.searchName}` : url + `&name=${vm.searchName}`;
        count++;
      }
      if(vm.marketingFilter != "all" && vm.marketingFilter){
        url = count == 0 ? url + `?user=${vm.marketingFilter}` : url + `&user=${vm.marketingFilter}`;
      }

      await vm.axios.get(url,
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        if(response.data){
          vm.listCollectedPhoneNumbers = [];

          if(vm.marketingFilter == "all"){
            vm.marketingFilterOptions = [
              { value: "all", text: "All Marketing" }
            ];
          }

          response.data.forEach(data => {
            vm.listCollectedPhoneNumbers.push(data);

            if(vm.involvedMarketingIds.indexOf(data.user_id) == -1 && vm.marketingFilter == "all"){
              vm.marketingFilterOptions.push({
                value: data.user_id,
                text: data.marketing_name
              })
            }
          })
        }
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.getData();
          }
        }
        else{
          vm.showSnackbar("Failed to Get Collected Phone Numbers Data.", err.message);
        }
      })
    },

    async checkEventPhoneNumber(phoneNumber) {
      const vm = this;
      let condition = true;

      vm.$store.state.isLoading = true;

      await vm.axios.post(`${vm.$store.state.baseApiURL}/api/events/phone-numbers/check`, {
        phone_number: phoneNumber,
        event_id: vm.eventData.id,
      },
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        if(response.data.id){
          condition = false;
        }
        else{
          condition = true;
        }
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.checkEventPhoneNumber(phoneNumber);
          }
        }
        else{
          vm.showSnackbar("Failed to Check Phone Number (Event).", err.message);
        }
      })

      vm.$store.state.isLoading = false;

      return condition;
    },

    async checkCustomerPhoneNumber(phoneNumber){
      const vm = this;
      let condition = true;

      await vm.axios.get(`${vm.$store.state.baseApiURL}/api/customers/check-number/${phoneNumber}`,
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        if(response.data.customer){
          vm.registeredPhoneNumberData = response.data.customer;
          condition = false;
        }
        else{
          condition = true;
        }
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.checkCustomerPhoneNumber(phoneNumber);
          }
        }
        else{
          vm.showSnackbar("Failed to Check Phone Number (Customer).", err.message);
        }
      })

      return condition;
    },
    
    async checkPhoneNumber(){
      const vm = this;
      console.log("kepanggil");
      // const { phone_number, event_id, user_id, customer_id, name, status, assigned } = req.body  
      let availableInEvent = await vm.checkEventPhoneNumber(vm.newPhoneNumberData.phone_number);

      if(availableInEvent){
        let availableInCustomer = await vm.checkCustomerPhoneNumber(vm.newPhoneNumberData.phone_number);

        if(availableInCustomer){
          vm.addPhoneNumber(false);
        }
        else{
          vm.formWarningMessage = "Phone Number is Already Registered as a Customer! Details below."
        }
        
      }
      else{
        vm.formWarningMessage = "Phone Number is Already Registered in this Event!";
      }
    },

    async addPhoneNumber(addAsCustomer) {
      const vm = this;

      vm.$store.state.isLoading = true;

      await vm.axios.post(`${vm.$store.state.baseApiURL}/api/events/phone-numbers/post`, {
        name: addAsCustomer == true ? vm.registeredPhoneNumberData.name : vm.newPhoneNumberData.name,
        phone_number: vm.newPhoneNumberData.phone_number,
        event_id: vm.eventData.id,
        user_id: vm.newPhoneNumberData.user_id,
        customer_id: addAsCustomer == true ? vm.registeredPhoneNumberData.id : null,
        assigned: 0,
        status: addAsCustomer == true ? 1 : 0,
      },
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.formWarningMessage = "";
        vm.registeredPhoneNumberData = null;
        vm.getData();
        vm.showSnackbar("Add Phone Number Success!", "");
        vm.stepCollapseShow = true;
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.addPhoneNumber(addAsCustomer);
          }
        }
        else{
          vm.showSnackbar("Failed to Add New Phone Number.", err.message);
        }
      })

      vm.registeredPhoneNumberData = null;
      vm.formWarningMessage = "";
      vm.resetAdd();
      vm.$store.state.isLoading = false;
    },

    async updatePhoneNumber() {
      const vm = this;

      vm.$store.state.isLoading = true;

      await vm.axios.put(`${vm.$store.state.baseApiURL}/api/events/phone-numbers/update/${vm.pickedPhoneNumber.id}`, {
        name: vm.pickedPhoneNumber.name,
        phone_number: vm.pickedPhoneNumber.phone_number,
        event_id: vm.eventData.id,
        user_id: vm.pickedPhoneNumber.user_id,
        customer_id: vm.pickedPhoneNumber.customer_id,
        assigned: vm.pickedPhoneNumber.assigned,
        status: vm.pickedPhoneNumber.status,
      },
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.getData();
        vm.showSnackbar("Update Phone Number Success!", "");
        vm.editPhoneNumberModalShow = false;
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.updatePhoneNumber();
          }
        }
        else{
          vm.showSnackbar("Failed to Update Phone Number.", err.message);
        }
      })

      vm.$store.state.isLoading = false;
    },

    editPhoneNumber(pickedPhoneNumber){
      const vm = this;

      vm.pickedPhoneNumber = JSON.parse(JSON.stringify(pickedPhoneNumber))
      vm.editPhoneNumberModalShow = true;
    },

    deletePhoneNumber(pickedPhoneNumber){
      const vm = this;

      vm.pickedPhoneNumber = JSON.parse(JSON.stringify(pickedPhoneNumber))
      vm.deletePhoneNumberModalShow = true;
    },

    showSnackbar(title, message) {
      this.snackbar.message = `${title} ${message}`;
      this.snackbar.show = true;
    },

    async getMarketingData(){
      const vm = this;
      let url = `${vm.$store.state.baseApiURL}/api/users/get?role=marketing`;

      await vm.axios.get(url,
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.listMarketing = [];
        vm.listMarketing.push({ value: null, text: "Pick someone..." })

        response.data.forEach(data => {
          vm.listMarketing.push({ value: data.id, text: data.name })
        })
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.getMarketingData();
          }
        }
        else{
          vm.showSnackbar("Failed to Get Marketing Data", err.message);
        }
      })
    },

    resetAdd(){
      const vm = this;

      vm.newPhoneNumberData = {
        phone_number: "",
        name: "",
        user_id: null,
      };
      
      vm.searchMarketingText = "";
      vm.registeredPhoneNumberData = null;
      vm.formWarningMessage = "";
    },

    resetFilter(){
      const vm = this;

      vm.searchName = "";
      vm.marketingFilter = "all";

      vm.getData();
    },

    async assignPhoneNumber(number){
      const vm = this;
      vm.pickedPhoneNumber = JSON.parse(JSON.stringify(number))

      if(vm.pickedPhoneNumber.status == 0){
        let availableInCustomer = await vm.checkCustomerPhoneNumber(vm.pickedPhoneNumber.phone_number);

        if(availableInCustomer){
          vm.registeredPhoneNumberData = null;
        }
      }

      vm.assignPhoneNumberModalShow = true;
    },

    async assign(condition){
      const vm = this;
      let url = `${vm.$store.state.baseApiURL}/api/events/post/add-customer`;

      vm.$store.state.isLoading = true;
      //new Date().getHours() < 21 && new Date().getHours() >= 8
      if(true){
        if(condition == 0){
          //add as customer
          url = `${vm.$store.state.baseApiURL}/api/events/post/add-customer`;
          await vm.axios.post(url, {
            id: vm.pickedPhoneNumber.id,
            name: vm.pickedPhoneNumber.name,
            phone_number: vm.pickedPhoneNumber.phone_number,
            user_id: vm.pickedPhoneNumber.user_id,
            event_id: vm.pickedPhoneNumber.event_id
          },
          {
            headers: {
              'Authorization': `Bearer ${vm.$store.state.accessToken}`
            }
          })
          .then((response) => {
            vm.registeredPhoneNumberData = null;
            vm.showSnackbar("Add as Customer Success!", "Tell the related marketing for further progress.");
          })
          .catch(async err => {
            if(err.response && err.response.data.message == "jwt expired"){
              let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
              if(accessToken.toString() == "expired"){
                vm.$router.push({ path:'/login' });
              }
              else{
                vm.assign(condition);
              }
            }
            else{
              vm.showSnackbar("Add as Customer Failed.", err.message);
            }
          })
        }
        else if(condition == 1){
          //new transaction
          url = `${vm.$store.state.baseApiURL}/api/events/post/new-transaction`;
          await vm.axios.post(url, {
            customer_id: vm.pickedPhoneNumber.customer_id,
            marketing_id: vm.pickedPhoneNumber.user_id,
            event_id: vm.pickedPhoneNumber.event_id
          },
          {
            headers: {
              'Authorization': `Bearer ${vm.$store.state.accessToken}`
            }
          }).then((response) => {
            if(response.data == "This customer has an active transaction"){
              vm.showSnackbar("New Transaction Failed.", "This customer has an active transaction.");
            }
            else{
              vm.registeredPhoneNumberData = null;
              vm.showSnackbar("New Transaction Success!", "Tell the related marketing for further progress.");
            }
            
          })
          .catch(async err => {
            if(err.response && err.response.data.message == "jwt expired"){
              let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
              if(accessToken.toString() == "expired"){
                vm.$router.push({ path:'/login' });
              }
              else{
                vm.assign(condition);
              }
            }
            else{
              vm.showSnackbar("New Transaction Failed.", err.message);
            }
          })
        }
        else if(condition == 2){
          //update customer to registered then new transaction
          vm.pickedPhoneNumber.status = 1;
          vm.pickedPhoneNumber.name = vm.registeredPhoneNumberData.name;
          vm.pickedPhoneNumber.customer_id = vm.registeredPhoneNumberData.id;
          
          await vm.axios.put(`${vm.$store.state.baseApiURL}/api/events/phone-numbers/update/${vm.pickedPhoneNumber.id}`, {
            name: vm.pickedPhoneNumber.name,
            phone_number: vm.pickedPhoneNumber.phone_number,
            event_id: vm.pickedPhoneNumber.event_id,
            user_id: vm.pickedPhoneNumber.user_id,
            customer_id: vm.pickedPhoneNumber.customer_id,
            assigned: vm.pickedPhoneNumber.assigned,
            status: vm.pickedPhoneNumber.status,
          },
          {
            headers: {
              'Authorization': `Bearer ${vm.$store.state.accessToken}`
            }
          })
          .then(async (response) => {
            url = `${vm.$store.state.baseApiURL}/api/events/post/new-transaction`;
        
            await vm.axios.post(url, {
              customer_id: vm.pickedPhoneNumber.customer_id,
              marketing_id: vm.pickedPhoneNumber.user_id,
              event_id: vm.pickedPhoneNumber.event_id
            },
            {
              headers: {
                'Authorization': `Bearer ${vm.$store.state.accessToken}`
              }
            })
            .then((response2) => {
              if(response2.data == "This customer has an active transaction"){
                vm.showSnackbar("New Transaction Failed.", "This customer has an active transaction.");
              }
              else{
                vm.registeredPhoneNumberData = null;
                vm.showSnackbar("New Transaction Success!", "Tell the related marketing for further progress.");
              }
            })
            .catch(async err2 => {
              if(err2.response.data.message == "jwt expired"){
                let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
                if(accessToken.toString() == "expired"){
                  vm.$router.push({ path:'/login' });
                }
                else{
                  vm.assign(condition);
                }
              }
              else{
                vm.showSnackbar("New Transaction Failed.", err.message);
              }
            })
          })
          .catch(async err => {
            if(err.response && err.response.data.message == "jwt expired"){
              let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
              if(accessToken.toString() == "expired"){
                vm.$router.push({ path:'/login' });
              }
              else{
                vm.assign(condition);
              }
            }
            else{
              vm.showSnackbar("Failed to Update Phone Number.", err.message);
            }
          })
        }
      }
      else{
        vm.showSnackbar("Action Failed.", "The operational hours is between 08:00 till 20:59 (8 AM - 9 PM)");
      }
      
      vm.assignPhoneNumberModalShow = false;
      vm.registeredPhoneNumberData = null;
      vm.$store.state.isLoading = false;
      vm.resetAdd();
      vm.getData();
    }
  },
  mounted(){
    const vm = this;

    vm.getMarketingData();

    vm.getData();
  }
};
</script>
<style>
  select {
    border: none;
    border-bottom: 1px solid #ced4da;
    margin: 2px !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
  }
</style>
