<template>
  <div class="content">
    <div class="md-layout">
    
      <div class="md-layout-item md-medium-size-100 md-size-33">
        <user-card :userData="userData"> </user-card>
        <md-card class="mt-0">
          <md-card-content class="text-center w-100">
            <b-row class="w-100 m-0" no-gutters>
              <b-col><h4 style="font-weight: 500">Customer Current Potential</h4></b-col>
            </b-row>
            <b-spinner v-if="ratingIsLoading" style="width:3rem; height:3rem; font-size:20px" variant="secondary" label="Spinning"></b-spinner>
            <b-row v-else class="w-100 m-0" no-gutters>
              <b-col class="mx-1">
                <md-radio style="margin:0" v-model="customerRating" :value="1"></md-radio><br>1
              </b-col>
              <b-col class="mx-1">
                <md-radio style="margin:0" v-model="customerRating" :value="2"></md-radio><br>2
              </b-col>
              <b-col class="mx-1">
                <md-radio style="margin:0" v-model="customerRating" :value="3"></md-radio><br>3
              </b-col>
              <b-col class="mx-1">
                <md-radio style="margin:0" v-model="customerRating" :value="4"></md-radio><br>4
              </b-col>
              <b-col class="mx-1">
                <md-radio style="margin:0" v-model="customerRating" :value="5"></md-radio><br>5
              </b-col>
            </b-row>
          </md-card-content>
        </md-card>
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Profile Detail Customer <br>( CUSTOMER ID: {{ routeParamsId }} )</h4>
          </md-card-header>

          <md-card-content>
            <form @submit.prevent="editCustomer">
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field>
                    <label>Name</label>
                    <md-input v-model="tempData.name" :disabled="!isEdit" required></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field>
                    <label>Phone Number</label>
                    <md-input 
                      v-model="tempData.phone_number" 
                      :disabled="!isEdit" 
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      type = "number"
                      maxlength = "13"
                      required>
                    </md-input>
                  </md-field>
                </div>

                <div v-if="!isEdit" class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Created At</label>
                    <md-input 
                      v-model="tempData.createdAtFormatted" 
                      disabled>
                    </md-input>
                  </md-field>
                </div>

                <div v-if="!isEdit" class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Last Updated</label>
                    <md-input v-model="tempData.updatedAtFormatted" disabled></md-input>
                  </md-field>
                </div>
              </div>

              <md-button
                v-if="(currentUser.role == 'admin' || currentUser.role == 'direksi') && !isEdit"
                @click="isEdit = true"
                class="me-2 md-warning"
              >
                Edit
              </md-button>

              <md-button
                v-if="isEdit"
                type="submit"
                class="me-2 md-success"
                @click="editCustomer()"
              >
                Save
              </md-button>

              <md-button
                v-if="isEdit"
                class="md-danger"
                size="md"
                @click="resetUserData()"
              >
                Cancel
              </md-button>
            </form>
          </md-card-content>
        </md-card>
      </div>
      <div class="md-layout-item md-medium-size-100 md-size-66">
        <active-transaction v-if="!dataIsLoading" :data="currentDetail.activeTransaction" :progressData="currentDetail.progress" :event="currentDetail.event" @refreshCustomerDetail="requestDataAPI"></active-transaction>
        <transaction-history v-if="!dataIsLoading" :data="currentDetail.finishedTransaction"></transaction-history>
      </div>
    </div>

    <md-snackbar :md-position="'center'" :md-duration="5000" :md-active.sync="snackbar.show" md-persistent>
      <span>{{ snackbar.message }}</span>
      <md-button class="md-primary" @click="snackbar.show = false">Close</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import { UserCard } from "@/pages";

export default {
  components: {
    UserCard,
  },
  data(){
    return{
      snackbar:{
        message: "",
        show: false,
      },
      userData: {},
      tempData: {},
      isEdit: false,
      customerDetail: {},
      activeTransactionIsReady: false,
      ratingIsLoading: false,
      customerRating: null,
    }
  },
  watch:{
    userData(){
      const vm = this;

      vm.userData.role = "customer";
      vm.userData.createdAtFormatted = new Date(vm.userData.created_at).toLocaleDateString();
      vm.userData.updatedAtFormatted = new Date(vm.userData.updated_at).toLocaleDateString();
      vm.tempData = JSON.parse(JSON.stringify(vm.userData));
      vm.customerRating = vm.tempData.rating;
    },
    customerDetail(){
      const vm = this;

      vm.userData = vm.customerDetail.customer[0]
    },
    customerRating(newValue, oldValue){
      const vm = this;
      
      if(
        vm.tempData.rating == null ||
        vm.customerRating.toString() != vm.tempData.rating.toString()
        ){
        vm.updateCustomerRating();
      }
    }
  },
  computed:{
    currentUser(){
      return this.$store.state.user;
    },
    currentCustomerId(){
      return this.$route.params.id;
    },
    customers(){
      return this.$store.state.customers;
    },
    currentDetail(){
      return this.customerDetail
    },
    routeParamsId(){
      this.requestDataAPI();
      return this.$route.params.id;
    },
  },
  methods:{
    async updateCustomerRating () {
      const vm = this;
      vm.ratingIsLoading = true;
      
      await vm.axios.put(`${vm.$store.state.baseApiURL}/api/customers/update/${vm.currentCustomerId}`, {
        name: vm.userData.name,
        phone_number: vm.userData.phone_number,
        rating: vm.customerRating
      }, {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.ratingIsLoading = false;
        vm.requestDataAPI();
        vm.showSnackbar("Update Rating Success!", "");
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.editCustomer();
          }
        }
        else{
          vm.showSnackbar("Failed to Update the Rating", err.message);
        }
      })
    },

    async requestDataAPI(){
      const vm = this;
      
      vm.dataIsLoading = true;
      await vm.axios.get(`${vm.$store.state.baseApiURL}/api/customers/details/${vm.currentCustomerId}`, 
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.dataIsLoading = false;
        vm.customerDetail = response.data;
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.requestDataAPI(url);
          }
        }
        else{
          vm.dataIsLoading = false;
          vm.showSnackbar(`Failed to Get Customer Data with ID: ${vm.currentCustomerId}`, err.message);
        }
      })

      vm.dataIsLoading = false;
    },

    async editCustomer () {
      const vm = this;
      vm.$store.state.isLoading = true;
      await vm.axios.put(`${vm.$store.state.baseApiURL}/api/customers/update/${vm.currentCustomerId}`, {
        name: vm.tempData.name,
        phone_number: vm.tempData.phone_number,
        rating: vm.customerRating
      }, {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.isEdit = false;
        vm.requestDataAPI();
        vm.showSnackbar("Update Customer Success!", "");
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.editCustomer();
          }
        }
        else{
          vm.showSnackbar("Failed to Update the Customer", err.message);
        }
      })
      vm.$store.state.isLoading = false;
    },

    resetUserData(){
      const vm = this;
      vm.tempData = JSON.parse(JSON.stringify(vm.userData));
      vm.isEdit = false;
    },
    showSnackbar(title, message) {
      this.snackbar.message = `${title}`;
      this.snackbar.show = true;
    },
  },
  async beforeMount(){
    const vm = this;
    await vm.requestDataAPI();
  }
};
</script>
