<template>
  <div 
    style="height:100%" 
    class="login-background" 
    :style="{ backgroundImage: 'url(' + require('@/assets/img/login-page-background.jpg') + ')' }"
    >
    <div class="layer">
      
    </div>
    <b-container class="h-100 py-3">
      <b-row class="w-100 my-3">
        <b-col align-self="center">
          <b-card no-body class="overflow-hidden" >
            <b-row no-gutters>
              <b-col class="d-md-block d-none" md="6">
                  <b-card-img 
                    alt="Image" 
                    :src="containerImage"
                    class="rounded-0"></b-card-img>
              </b-col>
              <b-col md="6" style="overflow-y: auto">
                <b-card-body>
                  <h3>Green Lake CRM Login Page</h3>
                  <b-card-text>
                    This is a login container. Please contact the executives or your lead if you found any technical problem.
                  </b-card-text>

                  <b-form @submit="onSubmit">

                    <b-form-group
                      class="my-4"
                      label="Username"
                      label-for="username"
                    >
                      <b-form-input
                        id="username"
                        v-model="form.username"
                        placeholder="Enter username"
                        required
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      class="mt-2 mb-2"
                      label="Password"
                      label-for="password"
                    >
                      <b-form-input
                        id="password"
                        v-model="form.password"
                        type="password"
                        placeholder="Enter password"
                        required
                      ></b-form-input>
                    </b-form-group>

                    <p 
                      :class="{ 'text-danger':invalidAccountMessage ? true : false }">
                      {{ invalidAccountMessage ? invalidAccountMessage : "We'll never share your account with anyone else." }}
                    </p>

                    <b-button 
                      class="px-4" 
                      type="submit" 
                      :variant="invalidAccountMessage ? 'danger' : 'success'">
                      LOGIN
                    </b-button>
                  </b-form>

                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      show: true,
      invalidAccountMessage: null
    }
  },
  components: {
  },
  props:{
    backgroundImage: {
      type: String,
      default: require("@/assets/img/login-page-background.jpg"),
    },
    containerImage: {
      type: String,
      default: require("@/assets/img/login-container-background.jpg"),
    },
    mainLogo: {
      type: String,
      default: require("@/assets/img/green-lake-main-logo.png"),
    },
  },
  watch:{
    form: {
      handler(val){
        const vm = this;
        vm.invalidAccountMessage = null;
      },
      deep: true
  }
  },
  methods:{
    async requestLogin(){
      const vm = this;
      vm.invalidAccountMessage = null;
      let user = {};
      let isLoggedIn = false;
      
      await vm.axios.post(`${vm.$store.state.baseApiURL}/login`, {
        username: vm.form.username,
        password: vm.form.password,
      })
      .then((response) => {
        if(response.status.toString() == "200"){
          vm.$store.state.user = response.data.user;
          vm.$store.state.accessToken = response.data.accessToken.accessToken;
          vm.$store.state.refreshToken = response.data.refreshToken;
          vm.$store.state.isLoggedIn = true;

          window.localStorage.setItem("storagePerumahanUser", JSON.stringify(vm.$store.state.user));
          window.localStorage.setItem("storagePerumahanIsLoggedIn", JSON.stringify(vm.$store.state.isLoggedIn));
          window.localStorage.setItem("storagePerumahanAccessToken", JSON.stringify(vm.$store.state.accessToken));
          window.localStorage.setItem("storagePerumahanRefreshToken", JSON.stringify(vm.$store.state.refreshToken));

          vm.$router.push({ path: '/' });
        }
      })
      .catch(async err => {
        vm.invalidAccountMessage = err.response.data.message;
      })
    },

    onSubmit(event) {
      event.preventDefault()

      const vm = this;

      vm.requestLogin();

      /*
      if(vm.form.username == "admin" && vm.form.password == "admin"){
        //contoh penanganan kalau admin yang masuk
        user = {
          id: 1,
          username: "admin",
          name: "admin",
          role: "admin",
          phoneNumber: "08xxxxxxxxxx"
        }
        isLoggedIn = true;
      }
      else if(vm.form.username == "marketing" && vm.form.password == "marketing"){
        user = {
          id: 2,
          username: "marketing",
          name: "marketing",
          role: "marketing",
          phoneNumber: "08xxxxxxxxxx"
        }
        isLoggedIn = true;
      }
      else if(vm.form.username == "lead_marketing" && vm.form.password == "lead_marketing"){
        user = {
          id: 3,
          username: "lead_marketing",
          name: "lead_marketing",
          role: "lead_marketing",
          phoneNumber: "08xxxxxxxxxx"
        }
        isLoggedIn = true;
      }
      else if(vm.form.username == "direksi" && vm.form.password == "direksi"){
        user = {
          id: 4,
          username: "direksi",
          name: "direksi",
          role: "direksi",
          phoneNumber: "08xxxxxxxxxx"
        }
        isLoggedIn = true;
      }
      
      if(isLoggedIn == true){
        vm.$store.state.user = user;
        vm.$store.state.isLoggedIn = true;
      
        window.localStorage.setItem("storagePerumahanUser", JSON.stringify(vm.$store.state.user));
        window.localStorage.setItem("storagePerumahanIsLoggedIn", JSON.stringify(vm.$store.state.isLoggedIn));

        if(vm.$store.state.nextRoute){
          vm.$router.push({ path:vm.$store.state.nextRoute })
        }
        else{
          vm.$router.push({ path:'/' })
        }
      }
      else{
        vm.invalidAccountMessage = "The username or password is invalid."
      }*/
      
      //window.localStorage.setItem("isLoggedIn", vm.$store.state.isLoggedIn);
    },
  },
};
</script>

<style>
  .login-background{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .layer {
    background-color: rgba(0,0,0,0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
