import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from "vuex-persistedstate";
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  key:"store",
  storage: window.localStorage
})

const dataState = createPersistedState({
  key:"myApp",
  paths: ['user','isLoggedIn']
})

const store = new Vuex.Store({
  state: {
    sidebarIsShowed: false,
    isLoading: false,
    nextRoute: null,
    baseApiURL: "https://apicrm.greenlakesurabaya.com",//"http://localhost:3000",
    //baseApiURL: "http://localhost:3000",
    count: 0,
    isLoggedIn: false,
    user:{
      id: null,
      username: null,
      name: null,
      phoneNumber: null,
      role: null
    },
    customers:[],
    events:[],
    eventStatistic: [],
    customerStatistic: [],
    marketingStatistic: [],
    transactions:[],
    pricelists:[],
    collectedPhoneNumbers:[],
    steps:[],
    progressCustomers:[],
    users:[],
    accessToken: null,
    refreshToken: null,
  },
  mutations: {
    increment (state) {
      state.count++
    },
    updateAccessToken(state, accessToken){
      state.accessToken = accessToken;
    }
    /*async refreshToken(state, refreshToken){
      const vm = this;
      console.log('refresh token')
      await Vue.axios.post(`${state.baseApiURL}/token`, {
        token: refreshToken
      })
      .then((response) => {
        console.log('refreshed, ', response)
        state.accessToken = response.data.accessToken.accessToken;
        window.localStorage.setItem("storagePerumahanAccessToken", JSON.stringify(state.accessToken));
        return state.accessToken;
      })
      .catch(err => {
        console.log("err =", err)
        if(err.response.data.message == "jwt expired"){
          return "expired";
        }
        else{
          return null;
        }
      })
    }*/
  },
  actions: {
    refreshToken({commit, state}, refreshToken){
      const vm = this;

      return Vue.axios.post(`${state.baseApiURL}/token`, {
        token: refreshToken
      })
      .then((response) => {
        //state.accessToken = response.data.accessToken.accessToken;
        commit('updateAccessToken', response.data.accessToken.accessToken)
        window.localStorage.setItem("storagePerumahanAccessToken", JSON.stringify(state.accessToken));
        return state.accessToken;
      })
      .catch(err => {
        if(err.response.data.message == "jwt expired"){
          return "expired";
        }
        else{
          return null;
        }
      })
    }
  },
  plugins: [
    /*new VuexPersistence({
      key: "vuexStorage",
      storage: window.localStorage,
      reducer: (state) => ({ user: state.user, isLoggedIn: state.isLoggedIn }), //only save navigation module
    }).plugin*/
    dataState
  ]
});

export default store
/*export default new Vuex.Store({
    state: {
      count: 0,
      isLoggedIn: false,
      user:{
        id: null,
        username: null,
        name: null,
        phoneNumber: null,
        role: null
      },
      customers:[],
      events:[],
      transactions:[],
      pricelists:[],
      collectedPhoneNumbers:[],
      steps:[],
      progressCustomers:[],
      users:[]
    },
    mutations: {
      increment (state) {
        state.count++
      }
    },
    plugins: [createPersistedState()],
})*/
