<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Latest Price List</h4>
            <p class="category">This page is containing the latest price list that updated by admin</p>
          </md-card-header>
          <md-card-content>
            <b-row>
              <b-col class="text-center">
                <h3><b>{{ pricelists.length > 0 ? pricelists[0].name : "No Pricelist Found" }}</b></h3>
                <h4><b>LAST UPDATED AT {{ pricelists.length > 0 ? new Date(pricelists[0].updatedAt).toLocaleDateString() : "" }}</b></h4>

                <md-button 
                  v-if="currentUser.role == 'admin' || currentUser.role == 'direksi'"
                  class="md-success me-2"
                  @click="addModalShow = true; pdfFile = null">
                  <md-icon>upload</md-icon>
                  Upload
                </md-button>

                <md-button 
                  class="md-info"
                  @click="downloadFile()"
                  :disabled="pricelists.length <= 0">
                  <md-icon>download</md-icon>
                  Download
                </md-button>
              </b-col>
            </b-row>
          </md-card-content>
        </md-card>

        <b-modal
          ref="modal"
          v-model="addModalShow"
          :hide-header-close="true"
          header-variant="success"
          hide-footer
        >
          <template #modal-header>
            <b-row class="w-100">
              <b-col>
                <h5><b>Upload Price List (PDF)</b></h5>
                <hr width="100%" style="border:2px solid #green; border-radius: 5px ">
              </b-col>
            </b-row>
          </template>

          <form ref="form" @submit.stop.prevent="uploadFile">
            <!-- Styled -->
            <b-form-file
              v-model="pdfFile"
              :state="Boolean(pdfFile)"
              placeholder="Choose a file or drop it here..."
              plain
              accept=".pdf"
            ></b-form-file>
            <div class="my-3"><b>Selected file: {{ pdfFile ? pdfFile.name : '' }}</b></div>

            <b-button
              variant="success"
              size="md"
              type="submit"
              class="me-2"
            >
              Submit
            </b-button>

            <b-button
              variant="danger"
              size="md"
              @click="resetAddModal()"
            >
              Cancel
            </b-button>
          </form>
        </b-modal>
      </div>
    </div>

    <md-snackbar :md-position="'center'" :md-duration="5000" :md-active.sync="snackbar.show" md-persistent>
      <span>{{ snackbar.message }}</span>
      <md-button class="md-primary" @click="snackbar.show = false">Close</md-button>
    </md-snackbar>
  </div>
</template>

<script>
export default {
  props:{
  },
  components: {
  },
  data(){
    return{
      snackbar:{
        message: "",
        show: false,
      },

      addModalShow: false,
      pdfFile: null,
    }
  },
  computed:{
    currentUser(){
      return this.$store.state.user;
    },
    pricelists(){
      return this.$store.state.pricelists;
    }
  },
  watch:{
  },
  methods:{
    detailCustomer(customerId){
      const vm = this;

      vm.$router.push(`/customer/${customerId}`);
    },
    resetAddModal(){
      const vm = this;
      vm.addModalShow = false;
    },

    async requestDataAPI(){
      const vm = this;

      vm.dataIsLoading = true;

      await vm.axios.get(`${vm.$store.state.baseApiURL}/api/files/get`, 
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.$store.state.pricelists = response.data;
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.requestDataAPI();
          }
        }
        else{
          vm.showSnackbar("Failed to Get Pricelists Data", err.message);
        }
      })
      
      vm.dataIsLoading = false;
    },

    async uploadFile(event) {
      event.preventDefault()
      /*const util = require('util'),
      request = util.promisify(require('request')),
      fs = require('fs'),
      fsp = fs.promises;*/
      const vm = this;

      let formData = new FormData();
      formData.append('file', vm.pdfFile);
      vm.$store.state.isLoading = true;
      await vm.axios.post(`${vm.$store.state.baseApiURL}/api/files/upload`,
      formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${vm.$store.state.accessToken}`,
          'User-Agent': 'Mozilla/5.0 (X11; Linux x86_64; rv:100.0) Gecko/20100101 Firefox/100.0'
        }
      }).then(response => {
        vm.showSnackbar("Upload Priceslist Success!", response.data);
      })
      .catch(async err => {
        console.log("err =", err)
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.uploadFile(event);
          }
        }
        else{
          vm.showSnackbar("Failed to Upload the Pricelist.", err.message);
        }
      });

      vm.requestDataAPI();
      vm.addModalShow = false;
      vm.$store.state.isLoading = false;
    },

    async downloadFile(){
      const vm = this;

      await vm.axios.get(`${vm.$store.state.baseApiURL}/api/files/download`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then(response => {
        vm.showSnackbar("Download Priceslist Success!", "");
        //ensure that the user can call `then()` only when the file has
        var blob = new Blob([response.data], {type:response.headers.get('content-type')});

        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = vm.pricelists[0].name;
        link.click();
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.downloadFile();
          }
        }
        else{
          vm.showSnackbar("Failed to Download the Pricelist.", err.message);
        }
      });

      vm.requestDataAPI();
      vm.addModalShow = false;
    },

    showSnackbar(title, message) {
      this.snackbar.message = `${title} ${message}`;
      this.snackbar.show = true;
    },
  },
  mounted(){
    const vm = this;

    vm.requestDataAPI();
  }
};
</script>

<style scoped>
</style>
