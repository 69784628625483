<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card v-if="currentUser.role == 'marketing' && myPendingTransactions.length > 0">
          <md-card-header data-background-color="green">
            <h4 class="title">Pending Transaction</h4>
            <p class="category">This section contains pending transactions that can be accepted. Aware of the expired date.</p>
          </md-card-header>

          <md-card-content style="max-height:300px ; overflow-y:auto">
            <!--<md-button class="md-primary" @click="getMyPendingTransaction">Load my Pending Transaction</md-button>-->
            <b-card class="mb-3" variant="success" show v-for="(transaction,i) in myPendingTransactions" :key="i">
              <h4 style="font-weight:500">
                Transaction {{ transaction.id }}
              </h4>
              <p style="font-weight:500">
                Assigned at: {{ new Date(transaction.updated_at).toLocaleDateString() }} - {{ new Date(transaction.updated_at).toLocaleTimeString() }} <br>
                Expired at: {{ new Date(new Date(transaction.updated_at).getTime() + (1*60*60*1000)).toLocaleDateString() }} - {{ new Date(new Date(transaction.updated_at).getTime() + (1*60*60*1000)).toLocaleTimeString() }}  
              </p>
              <a class="text-white" :href="`/#/accept-transaction/${transaction.id}/${transaction.marketing_id}`" target="_blank">
                <b-button 
                  variant="success">
                    Accept
                </b-button>
              </a>
            </b-card>
          </md-card-content>
        </md-card>

        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Customer Data</h4>
            <p class="category">This table contains customer data.</p>
          </md-card-header>

          <md-card-content 
            v-if="dataIsLoading"
            class="text-center">
            <b-spinner style="width:5rem; height:5rem; font-size:25px" variant="success" label="Spinning"></b-spinner>
          </md-card-content>

          <md-card-content
            v-else
          >
            <b-row>
              <b-col sm="12"  md="3"></b-col>
              <b-col sm="12"  md="3"></b-col>
              <b-col sm="12" md="3" class="text-right">
                <md-button 
                  v-if="currentUser.role == 'admin' || currentUser.role == 'direksi'"
                  class="md-success w-100"
                  @click="addModalShow = true">
                  <md-icon>library_add</md-icon>
                  New Customer
                </md-button>
              </b-col>
              <b-col sm="12" md="3" class="text-right">
                <md-button 
                  v-if="currentUser.role == 'admin' || currentUser.role == 'direksi'"
                  class="w-100"
                  :class="{ 'md-info': !dataFilterShow, 'md-danger': dataFilterShow }"
                  @click="dataFilterShow = !dataFilterShow">
                  <md-icon>{{ dataFilterShow == true ? 'arrow_circle_up' : 'arrow_circle_down' }}</md-icon>
                  {{ dataFilterShow == true ? 'Hide Data Filter' : 'Show Data Filter' }}
                </md-button>
              </b-col>
            </b-row>

            <b-collapse v-model="dataFilterShow">
              <b-row>
                <b-col sm="12" md="3">
                  <md-field>
                    <label>Search</label>
                    <md-input v-model="searchName" placeholder="Search by name"></md-input>
                    <b-button
                      variant="secondary"
                      size="sm"
                      @click="getData()"
                      >
                      <md-icon class="text-white">search</md-icon>
                    </b-button>
                  </md-field>
                </b-col>

                <b-col sm="12" md="3">
                  <md-field>
                    <label>Search</label>
                    <md-input v-model="searchPhone" placeholder="Search by phone number"></md-input>
                    <b-button
                      variant="secondary"
                      size="sm"
                      @click="getData()"
                      >
                      <md-icon class="text-white">search</md-icon>
                    </b-button>
                  </md-field>
                </b-col>

                <b-col sm="12" md="3">
                  <md-field>
                    <label>Search</label>
                    <md-input v-model="searchId" placeholder="Search by ID"></md-input>
                    <b-button
                      variant="secondary"
                      size="sm"
                      @click="getData()"
                      >
                      <md-icon class="text-white">search</md-icon>
                    </b-button>
                  </md-field>
                </b-col>

                <b-col sm="12" md="3">
                  <md-field>
                    <b-form-select 
                      class="w-100"
                      v-model="ratingFilter" 
                      :options="filterRatingOptions"
                      style="border-bottom: none">
                    </b-form-select>
                  </md-field>
                </b-col>

                <b-col sm="12" md="3">
                  <md-field>
                    <b-form-select 
                      class="w-100"
                      v-model="stepFilter" 
                      :options="filterStepOptions"
                      style="border-bottom: none">
                    </b-form-select>
                  </md-field>
                </b-col>

                <b-col sm="12" md="3">
                  <md-field>
                    <b-form-select 
                      class="w-100"
                      v-model="eventFilter" 
                      :options="eventFilterOptions"
                      style="border-bottom: none">
                    </b-form-select>
                  </md-field>
                </b-col>

                <b-col sm="12" md="3">
                  <md-field>
                    <b-form-select 
                      class="w-100"
                      v-model="perPage" 
                      :options="perPageOptions"
                      style="border-bottom: none">
                    </b-form-select>
                  </md-field>
                </b-col>

                <b-col sm="12" md="3">
                  <md-button 
                    class="md-primary w-100"
                    @click="resetFilter()">
                    <md-icon>refresh</md-icon>
                    Reset Filter
                  </md-button>
                </b-col>
              </b-row>
            </b-collapse>

            <b-table 
              style="max-height: 400px; overflow-y: auto"
              responsive hover 
              :items="filteredItems" 
              :fields="fields">

              <template #cell(rating)="data">
                <span
                  v-if="data.item.rating == null"
                  class="py-1 px-2 text-white"
                  style="border-radius:5px; font-weight: 550; white-space: nowrap"
                  :style="{
                    'background-color': data.item.rating == null ? '#dc3545' : '#28a745'
                  }">
                  {{ data.item.rating == null ? "NOT RATED" : data.item.rating }}
                </span>
                <div class="d-block" v-else>
                  <md-icon 
                    v-for="i in parseInt(data.item.rating)" 
                    :key="i" 
                    style="color: #ffc107;">
                  grade</md-icon>
                </div>
              </template>

              <template #cell(created_at)="data">
                {{ new Date(data.value).toLocaleDateString() }}
              </template>

              <template #cell(updated_at)="data">
                {{ new Date(data.value).toLocaleDateString() }}
              </template>

              <template #cell(step_name)="data">
                <span 
                  v-if="data.value == 'pending'" 
                  class="py-1 px-2"
                  style="background-color:#ffc107; border-radius:5px; font-weight: 550; white-space: nowrap">
                  {{ data.value.toUpperCase() }}
                </span>
                <span 
                  v-else-if="data.value == 'deal'" 
                  class="py-1 px-2 text-white "
                  style="background-color:#dc3545; border-radius:5px; font-weight: 550; white-space: nowrap">
                  {{ data.value.toUpperCase() }}
                </span>
                <span 
                  v-else
                  class="py-1 px-2 text-white "
                  style="background-color:#28a745 ; border-radius:5px; font-weight: 550; white-space: nowrap">
                  {{ data.value.toUpperCase() }}
                </span>
              </template>

              <template #cell(action)="data">
                <b-button
                  variant="primary"
                  class="ms-2 mt-1"
                  size="sm"
                  @click="detailCustomer(data.item.id)"
                  >
                  <md-icon class="text-white">launch</md-icon>
                </b-button>
                <b-button
                  v-if="currentUser.role == 'direksi' || currentUser.role == 'admin'"
                  variant="warning"
                  size="sm"
                  class="ms-2 mt-1"
                  @click="editCustomer(data.item)"
                  >
                  <md-icon>edit</md-icon>
                </b-button>
                <b-button
                  v-if="
                    (currentUser.role == 'direksi' || currentUser.role == 'admin') 
                    && (data.item.step_id != 2 && data.item.step_id != 4)"
                  variant="danger"
                  size="sm"
                  class="ms-2 mt-1"
                  @click="cancelTransaction(data.item)"
                  >
                  <md-icon class="text-white">cancel</md-icon>
                </b-button>
                <b-button
                  v-if="
                    (currentUser.role == 'direksi' || currentUser.role == 'admin') 
                    && (data.item.step_id == 2 || data.item.step_id == 4)"
                  variant="success"
                  size="sm"
                  class="ms-2 mt-1"
                  @click="createTransaction(data.item)"
                  >
                  <md-icon class="text-white">add_circle</md-icon>
                </b-button>
                
                <!--b-button
                  v-if="currentUser.role == 'direksi'"
                  variant="danger"
                  size="sm"
                  class="ms-2 mt-1"
                  @click="deleteCustomer(data.value)"
                  >
                  <md-icon class="text-white">delete </md-icon>
                </b-button>-->
              </template>

            </b-table>  
          </md-card-content>

          <md-card-content>
            <b-row>
              <b-col>
                <b-button
                  :disabled="previousPageDisabled"
                  variant="primary"
                  size="sm"
                  @click="changePage('prev')"
                  >
                  <md-icon class="text-white">arrow_left</md-icon>
                </b-button>
                
                <b-button
                  disabled
                  variant="success"
                  size="sm"
                  class="mx-2 px-3"
                  >
                  <span style="font-size:1.05rem; font-weight:700">{{ currentPage }}</span>
                </b-button>

                <b-button
                  :disabled="nextPageDisabled"
                  variant="primary"
                  size="sm"
                  @click="changePage('next')"
                  >
                  <md-icon class="text-white">arrow_right</md-icon>
                </b-button>
              </b-col>
            </b-row>
          </md-card-content>
        </md-card>

        <b-modal
          ref="modal"
          v-model="addModalShow"
          :hide-header-close="true"
          header-variant="success"
          hide-footer
        >
          <template #modal-header>
            <b-row class="w-100 mx-auto">
              <b-col class="p-0">
                <b-alert show variant="success">
                  <b style="font-size: 1.2rem">Add New Customer</b>
                </b-alert>
              </b-col>
            </b-row>
          </template>

          <form ref="form" @submit.stop.prevent="checkNewDataPhoneNumber">
            <b-form-group
            >
              <label for="name-input"><b>Name</b></label>
              <b-form-input
                id="name-input"
                v-model="newData.name"
                placeholder="Input the customer name here..."
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              class="my-4"
            >
            <label for="phone-input"><b>Phone Number (13 Digit Max)</b></label>
              <b-form-input
                id="phone-input"
                v-model="newData.phone_number"
                placeholder="Do not use +62 etc. Example: 08181133333"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "13"
                required
              ></b-form-input>
            </b-form-group>

            <b-card v-if="registeredPhoneNumberData">
              <b class="text-danger">Phone Number is Registered! Details Below</b>
              <span v-for="(data, name, i) in registeredPhoneNumberData.customer" :key="i">
                <br>
                <b>
                  {{ name }}:
                </b>
                {{ name == "created_at" || name == "updated_at" ? new Date(data).toLocaleDateString() : data }}
              </span>
              <hr>
              <span>
                <b>LAST PROGRESS : {{ registeredPhoneNumberData.progress.step_name.toUpperCase() }}</b>
              </span>
              <hr>
              <b class="text-danger">Attention</b>
              <ul>
                <li>
                  Press "Continue Add" if you still want to add the new customer. The marketing will automatically assigned to handle 
                  the transaction (status is pending).
                </li>
                <li>
                  Press "New Transaction" if you want to make a new transaction for the registered customer instead of add new customer.
                  <b>ONLY AVAILABLE FOR DEAL / CANCELLED TRANSACTION</b>. If you want to make a new transaction for the registered customer 
                  but the transaction is not deal / cancelled, you can cancel it first.
                </li>
              </ul>
            </b-card>

            <md-button
              type="submit"
              class="me-2 md-success"
              v-if="!registeredPhoneNumberData"
            >
              Submit
            </md-button>

            <md-button
              class="me-2 md-success"
              @click="addModalShow = false; createTransaction(registeredPhoneNumberData.customer)"
              v-if="registeredPhoneNumberData && (registeredPhoneNumberData.progress.step_id == 2 || registeredPhoneNumberData.progress.step_id == 4)"
            >
              New Transaction
            </md-button>

            <md-button
              class="me-2 md-warning"
              @click="insertCustomer()"
              v-if="registeredPhoneNumberData"
            >
              Continue Add
            </md-button>

            <md-button
              class="md-danger"
              size="md"
              @click="resetAddModal()"
            >
              Cancel
            </md-button>
          </form>
        </b-modal>

        <b-modal
          ref="editModal"
          v-model="editModalShow"
          :hide-header-close="true"
          header-variant="warning"
          hide-footer
          v-if="pickedCustomer"
        >
          <template #modal-header>
            <b-row class="w-100 mx-auto">
              <b-col class="p-0">
                <b-alert show variant="warning">
                  <b style="font-size: 1.2rem">Edit Customer</b>
                </b-alert>
              </b-col>
            </b-row>
          </template>

          <form ref="form" @submit.stop.prevent="updateCustomer">
            <b-form-group
            >
              <label for="name-input"><b>Name</b></label>
              <b-form-input
                id="name-input"
                v-model="pickedCustomer.name"
                placeholder="Input the customer name here..."
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              class="my-4"
            >
            <label for="phone-input"><b>Phone Number (13 Digit Max)</b></label>
              <b-form-input
                id="phone-input"
                v-model="pickedCustomer.phone_number"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "13"
                placeholder="Do not use +62 etc. Example: 08181133333"
                required
              ></b-form-input>
            </b-form-group>
            <md-button
              type="submit"
              class="me-2 md-success"
            >
              Save
            </md-button>

            <md-button
              class="md-danger"
              size="md"
              @click="editModalShow = false"
            >
              Cancel
            </md-button>
          </form>
        </b-modal>

        <b-modal
          v-model="cancelModalShow"
          :hide-header-close="true"
          header-variant="danger"
          hide-footer
          v-if="pickedCustomer"
        >
          <template #modal-header>
            <b-row class="w-100 mx-auto">
              <b-col class="p-0">
                <b-alert show variant="danger">
                  <b style="font-size: 1.2rem">Cancel Customer Transaction</b>
                </b-alert>
              </b-col>
            </b-row>
          </template>

          <h5>
            The current transaction of customer named <b>{{  pickedCustomer.name }}</b> will be canceled. Are you sure?
          </h5>
          <hr>
          <b-row>
            <b-col>
              <b-button
                class="me-2"
                size="sm"
                variant="warning"
                @click="cancelCustomerTransaction()"
              >
                Yes
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                @click="cancelModalShow = false;"
              >
                No
              </b-button>
            </b-col>
          </b-row>
        </b-modal>

        <b-modal
          v-model="newTransactionModalShow"
          :hide-header-close="true"
          header-variant="success"
          hide-footer
          v-if="pickedCustomer"
        >
          <template #modal-header>
            <b-row class="w-100 mx-auto">
              <b-col class="p-0">
                <b-alert show variant="success">
                  <b style="font-size: 1.2rem">Create Customer Transaction</b>
                </b-alert>
              </b-col>
            </b-row>
          </template>

          <h5>
            A new transaction for the customer named <b>{{  pickedCustomer.name }}</b> will be created. Are you sure?
          </h5>
          <b-card v-if="registeredPhoneNumberData">
            <b class="text-danger">Last Marketing that Handled this Customer</b><br>

            <span>
              <b>Name :</b> {{ registeredPhoneNumberData.transaction.marketing_name }}
            </span><br>
            <span>
              <b>ID :</b> {{ registeredPhoneNumberData.transaction.marketing_id }}
            </span>
            <hr>
            <b class="text-danger">Attention</b><br>
            <ul>
              <li>
                Press "Assign Last Marketing" if you want the <b>last marketing</b> to handle this customer's new transaction. The transaction progress 
                will be <b>Follow Up</b>
              </li>
              <li>
                Press "Assign New Marketing" if you want <b>new marketing (automatic from rotation)</b> to handle this customer's new transaction. The transaction
                progress will be <b>Pending</b>
              </li>
            </ul>
          </b-card>
          <hr>
          <b-row>
            <b-col>
              <b-button
                class="me-2"
                size="sm"
                variant="warning"
                @click="createCustomerTransaction(registeredPhoneNumberData.transaction.marketing_id)"
              >
                Assign Last Marketing
              </b-button>
              <b-button
                class="me-2"
                size="sm"
                variant="success"
                @click="createCustomerTransaction(null)"
              >
                Assign New Marketing
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                @click="registeredPhoneNumberData = null; newTransactionModalShow = false;"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-modal>
      </div>
    </div>

    <md-snackbar :md-position="'center'" :md-duration="5000" :md-active.sync="snackbar.show" md-persistent>
      <span>{{ snackbar.message }}</span>
      <md-button class="md-primary" @click="snackbar.show = false">Close</md-button>
    </md-snackbar>
  </div>
</template>

<script>

export default {
  data(){
    return{
      snackbar:{
        message: "",
        show: false,
      },

      dataIsLoading: false,
      addModalShow: false,
      editModalShow: false,
      deleteModalShow: false,
      cancelModalShow: false,
      newTransactionModalShow: false,
      newData:{
        name: "",
        phone_number: "",
      },
      pickedCustomer:{
        name: "",
        phone_number: "",
      },
      currentPage: 1,
      perPage: 25,
      perPageOptions:[
          { value: 25, text: '25 data each page' },
          { value: 50, text: '50 data each page' },
          { value: 100, text: '100 data each page' },
          { value: 200, text: '200 data each page' }
      ],
      fields: [
        {
          key: 'id',
          sortable: true
        },
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'phone_number',
          sortable: false
        },
        {
          key: 'rating',
          label: "Rating",
          sortable: false
        },
        {
          key: 'created_at',
          sortable: false
        },
        {
          key: 'updated_at',
          sortable: false
        },
        {
          key: 'step_name',
          sortable: false
        },
        {
          key: 'marketing_name',
          label: 'Handled By',
          sortable: false
        },
        {
          key: 'action',
          label: 'Action'
        }
      ],
      items: [],
      searchName: "",
      searchPhone: "",
      searchId: "",
      dataFilterShow: true,
      registeredPhoneNumberData: null,
      myPendingTransactions:[],

      filterStepOptions: [],
      stepFilter: "all",

      eventFilter: "all",
      eventFilterOptions: [
        { value: "all", text: "All Customer" },
        { value: 1, text: "From Event Only" },
        { value: 0, text: "From Admin Only" }
      ],

      ratingFilter: "all",
      filterRatingOptions: [
        { value: "all", text: "All Rating" },
        { value: 1, text: "Rated 1" },
        { value: 2, text: "Rated 2" },
        { value: 3, text: "Rated 3" },
        { value: 4, text: "Rated 4" },
        { value: 5, text: "Rated 5" }
      ],
    }
  },
  components: {
  },
  computed:{
    baseApiURL(){
      return this.$store.state.baseApiURL;
    },
    currentUser(){
      return this.$store.state.user;
    },
    transactions(){
      return this.$store.state.transactions;
    },
    customers(){
      return this.$store.state.customers;
    },
    steps(){
      return this.$store.state.steps;
    },
    totalRows(){
      let totalRow = Math.ceil(this.filteredItems.length/this.perPage)
      return totalRow;
    },
    nextPageDisabled(){
      const vm = this;

      if(vm.perPage <= vm.filteredItems.length){
        return false;
      }
      else{
        return true;
      }
    },
    previousPageDisabled(){
      const vm = this;

      if(vm.currentPage > 1){
        return false;
      }
      else{
        return true;
      }
    },
    filteredItems(){      
      let filteredItems = [];
      const vm = this;

      filteredItems = vm.customers;

      return filteredItems;
    }
  },
  watch:{
    perPage(newValue, oldValue) {
      if(newValue != oldValue){
        const vm = this;
        
        vm.currentPage = 1;
        vm.getData();
      }
    },
    stepFilter(newValue, oldValue) {
      if(newValue != oldValue){
        const vm = this;

        vm.currentPage = 1;
        vm.getData();
      }
    },
    eventFilter(newValue, oldValue) {
      if(newValue != oldValue){
        const vm = this;

        vm.currentPage = 1;
        vm.getData();
      }
    },
    ratingFilter(newValue, oldValue) {
      if(newValue != oldValue){
        const vm = this;

        vm.currentPage = 1;
        vm.getData();
      }
    },
  },
  methods:{

    async getStepsData(){
      const vm = this;

      await vm.axios.get(`${vm.$store.state.baseApiURL}/api/steps/get`,
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.$store.state.steps = response.data;
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.getStepsData();
          }
        }
        else{
          vm.showSnackbar("Failed to Get Steps Data", err.message);
        }
      })

      vm.filterStepOptions = [
        { value: "all", text: "All Step" },
      ];
      vm.steps.forEach(step => {
        vm.filterStepOptions.push({ value:step.id, text:step.name.toUpperCase() });
      });
    },

    changePage(condition){
      const vm = this;

      if(condition == 'next'){
        vm.currentPage++;
        vm.getData();
      }
      else{
        vm.currentPage--;
        vm.getData();
      }
    },

    async cancelCustomerTransaction(){
      const vm = this;

      vm.cancelModalShow = false;
      vm.$store.state.isLoading = true;

      await vm.axios.post(`${vm.$store.state.baseApiURL}/api/transactions/progress/post`, {
        step_id: 4,
        notes: "Transaction Canceled.",
        transaction_id: vm.pickedCustomer.transaction_id,
        customer_id: vm.pickedCustomer.id
      },
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.registeredPhoneNumberData = null
        vm.pickedCustomer = null;
        vm.showSnackbar("Cancel Transaction Success!", "");
        vm.getData();
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.cancelTransaction();
          }
        }
        else{
          vm.showSnackbar("Failed to Cancel Transaction.", err.response.data);
        }
      })

      vm.$store.state.isLoading = false;
    },

    async createCustomerTransaction(marketing_id){
      const vm = this;
      vm.newTransactionModalShow = false;
      vm.$store.state.isLoading = true;

      await vm.axios.post(`${vm.$store.state.baseApiURL}/api/customers/post/new-transaction`, {
        customer_id: vm.pickedCustomer.id,
        marketing_id: marketing_id,
      },
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.registeredPhoneNumberData = null
        vm.pickedCustomer = null;
        vm.showSnackbar("Create New Transaction Success!", "");
        vm.getData();
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.createCustomerTransaction(marketing_id);
          }
        }
        else{
          vm.showSnackbar("Failed to Create New Transaction.", err.response.data);
        }
      })

      vm.$store.state.isLoading = false;
    },

    getData(){
      const vm = this;
      let url = `${vm.baseApiURL}/api/customers/get?page=${vm.currentPage}&size=${vm.perPage}`;

      if(vm.searchName != "" && vm.searchName){
        url = url + `&name=${vm.searchName}`;
      }
      if(vm.searchPhone != "" && vm.searchPhone){
        url = url + `&phone_number=${vm.searchPhone}`;
      }
      if(vm.searchId != "" && vm.searchId){
        url = url + `&id=${vm.searchId}`;
      }
      if(vm.currentUser.role == "marketing"){
        url = url + `&marketing_id=${vm.currentUser.id}`;
      }
      if(vm.stepFilter != "all"){
        url = url + `&step=${vm.stepFilter}`;
      }
      if(vm.eventFilter != "all"){
        url = url + `&isFromEvent=${vm.eventFilter}`;
      }
      if(vm.ratingFilter != "all"){
        url = url + `&rating=${vm.ratingFilter}`;
      }

      vm.requestDataAPI(url);
    },

    async requestDataAPI(url){
      const vm = this;
      vm.dataIsLoading = true;
      await vm.axios.get(url,{
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        if(Array.isArray(response.data)){        
          vm.$store.state.customers = response.data.sort((a,b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
        }
        else{
          vm.$store.state.customers = [];
        }
      })
      .catch(async err => {
        if(err.response.data.message == "jwt expired" || err.response.data.message == "jwt malformed"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.requestDataAPI(url);
          }
        }
        else{
          vm.showSnackbar("Failed to Get Customers Data", err.message);
        }
      })
      
      vm.dataIsLoading = false;
    },

    detailCustomer(customerId){
      const vm = this;

      vm.$router.push(`/customer/${customerId}`);
    },
    editCustomer(customerData){
      const vm = this;
      
      vm.pickedCustomer = JSON.parse(JSON.stringify(customerData));
      vm.editModalShow = true;
    },
    cancelTransaction(customerData){
      const vm = this;
      
      vm.pickedCustomer = JSON.parse(JSON.stringify(customerData));
      vm.cancelModalShow = true;
    },
    createTransaction(customerData){
      const vm = this;
      vm.pickedCustomer = JSON.parse(JSON.stringify(customerData));
      
      vm.checkPhoneNumber(vm.pickedCustomer.phone_number, false);
      vm.newTransactionModalShow = true;
    },
    deleteCustomer(customerId){
      const vm = this;
      
      vm.pickedCustomer = vm.filteredItems.find(item => item.id == customerId);
      vm.deleteModalShow = true;
    },

    async updateCustomer() {
      const vm = this;
      vm.editModalShow = false;
      vm.$store.state.isLoading = true;

      await vm.axios.put(`${vm.$store.state.baseApiURL}/api/customers/update/${vm.pickedCustomer.id}`, {
        name: vm.pickedCustomer.name,
        phone_number: vm.pickedCustomer.phone_number,
      },
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.getData();
        vm.showSnackbar("Update Customer Success!", "");
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.updateCustomer();
          }
        }
        else{
          vm.showSnackbar("Failed to Update the Customer.", err.message);
        }
      })

      vm.$store.state.isLoading = false;
    },

    resetAddModal(){
      const vm = this;

      vm.newData.name = "";
      vm.newData.phone_number = "";
      vm.registeredPhoneNumberData = null;
      vm.addModalShow = false;
      vm.$store.state.isLoading = false;
    },

    checkNewDataPhoneNumber(){
      const vm = this;
      vm.checkPhoneNumber(vm.newData.phone_number, true);
    },

    async checkPhoneNumber(phoneNumber, isInsert){
      const vm = this;
      vm.$store.state.isLoading = true;

      await vm.axios.get(`${vm.$store.state.baseApiURL}/api/customers/check-number/${phoneNumber}`,
      {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        if(response.data.customer){
          vm.registeredPhoneNumberData = response.data;
        }
        else{
          if(isInsert == true){
            vm.insertCustomer();
          }
        }
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.checkPhoneNumber(phoneNumber, isInsert);
          }
        }
        else{
          vm.showSnackbar("Failed to Check Phone Number.", err.message);
        }
      })

      vm.$store.state.isLoading = false;
    },
    
    async insertCustomer() {
      const vm = this;
      
      vm.$store.state.isLoading = true;
      //new Date().getHours() < 21 && new Date().getHours() >= 8
      if (new Date().getHours() < 21 && new Date().getHours() >= 8) {
        await vm.axios.post(`${vm.$store.state.baseApiURL}/api/customers/post`, {
          name: vm.newData.name,
          phone_number: vm.newData.phone_number,
        }, 
        {
          headers: {
            'Authorization': `Bearer ${vm.$store.state.accessToken}`
          }
        })
        .then((response) => {
          vm.getData();
          vm.showSnackbar("Add Customer Success!", "");
        })
        .catch(async err => {
          if(err.response && err.response.data.message == "jwt expired"){
            let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
            if(accessToken.toString() == "expired"){
              vm.$router.push({ path:'/login' });
            }
            else{
              vm.insertCustomer();
            }
          }
          else{
            vm.showSnackbar("Failed to Add New Customer.", err.message);
          }
        })
      }
      else{
        vm.showSnackbar("Failed to Add New Customer.", "The operational hours is between 08:00 till 20:59 (8 AM - 9 PM)");
      }

      vm.resetAddModal();
    },

    showSnackbar(title, message) {
      this.snackbar.message = `${title} ${message}`;
      this.snackbar.show = true;
    },

    resetFilter(){
      const vm = this;

      vm.searchName = "";
      vm.searchPhone = "";

      vm.getData();
    },

    async getMyPendingTransaction(){
      const vm = this;
      
      await vm.axios.get(`${vm.$store.state.baseApiURL}/api/transactions/pending/${vm.currentUser.id}`, {
        headers: {
          'Authorization': `Bearer ${vm.$store.state.accessToken}`
        }
      })
      .then((response) => {
        vm.myPendingTransactions = [];
        if(response.data.length > 0 != null){
          vm.myPendingTransactions = response.data;
        }
      })
      .catch(async err => {
        if(err.response && err.response.data.message == "jwt expired"){
          let accessToken = await vm.$store.dispatch("refreshToken", vm.$store.state.refreshToken);
          if(accessToken.toString() == "expired"){
            vm.$router.push({ path:'/login' });
          }
          else{
            vm.getMyPendingTransaction();
          }
        }
        else{
          vm.showSnackbar("Failed to Get Pending Transactions.", err.message);
        }
      })
    },
  },
  async mounted(){
    const vm = this;
    
    await vm.getData();
    await vm.getStepsData();

    if(vm.currentUser.role == 'marketing'){
      await vm.getMyPendingTransaction();
    }
  },
};
</script>

<style scoped>
  .test{
    background-color: red;
  }
</style>
